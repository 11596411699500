import {useState, useEffect} from 'react'

import {Grid, Container,Typography} from '@mui/material'

import CustomTypography from '../Reusable/CustomTypography'; 

import {PrimaryColor,SecondaryColor} from '../Reusable/CustomThemes'
import { styled } from '@mui/system';
import axios from 'axios';
import {Loading} from '../components/Loading'
import { Helmet } from 'react-helmet-async';

const CustomHeading = styled(Typography)(({   }) => ({
    position: 'relative',
    color:SecondaryColor,padding:"15px",
    display: 'inline-block',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: '50%',
      borderColor: SecondaryColor,
      borderStyle: 'solid',
    },
    '&:before': {
      top: 0,
      left: 0,
      borderTopWidth: '5px',
      borderLeftWidth: '5px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
    },
    '&:after': {
      bottom: 0,
      right: 0,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: '5px',
      borderRightWidth: '5px',
    },
  }));


  
export const Privacy=()=>{


    const [isLoading, setIsLoading] = useState(true);
  const [metaTitle,setMetaTitle] = useState("");
  const [metaKeywords,setMetaKeywords] = useState("");
  const [metaDesc,setMetaDesc] = useState("");


  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const getData=()=>{
    axios.get("/user/get_privacy_data"   ).then((res) => {
      if (res.data.status) {
        setMetaTitle(res.data.metadata.meta_title)
        setMetaDesc(res.data.metadata.meta_desc)
        setMetaKeywords(res.data.metadata.meta_keywords)
        setIsLoading(false)
        handleClick();
      } 
    });
  }


  useEffect(()=>{
    getData();
  },[])


    return (<div>
        {isLoading ? (
      <Loading />
    ) : (<Container>
        <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metaKeywords} />

      </Helmet>
            <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            >
                <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>
                    <CustomHeading variant="h4">
                    Privacy Notice
                    </CustomHeading>
                    </Grid>
                    <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>

                    </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                    <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Introduction</b>
                    </CustomTypography>
                    <CustomTypography>
                    varshisartgallery.com ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Information We Collect</b>
                    </CustomTypography>
                    <CustomTypography>
                    When you contact us through our website, we may collect the following information:
                    <ul style={{color:SecondaryColor}}>
                        <li>Name</li>
                        <li>Email address</li>
                        <li>Mobile number</li>
                        <li>Subject</li>
                        <li>Message</li>
                    </ul>
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>How We Use Your Information</b>
                    </CustomTypography>
                    <CustomTypography>
                    We use the information we collect to:
                    <ul style={{color:SecondaryColor}}>
                        <li>Respond to your inquiries</li>
                        <li>Improve our website and services</li>
                        <li>Communicate with you about updates or promotions</li>
                    </ul>
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Data Security</b>
                    </CustomTypography>
                    <CustomTypography>
                    We implement appropriate technical and organizational measures to protect your information against unauthorized access, alteration, disclosure, or destruction.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Cookies</b>
                    </CustomTypography>
                    <CustomTypography>
                    Our website may use cookies to enhance your browsing experience. Cookies are small files stored on your device that help us understand how you use our site. You can choose to disable cookies through your browser settings.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Third-Party Links</b>
                    </CustomTypography>
                    <CustomTypography>
                    Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these websites.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Changes to This Privacy Policy</b>
                    </CustomTypography>
                    <CustomTypography>
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. Your continued use of the site after any changes have been made will constitute your acceptance of the new policy.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Contact Us</b>
                    </CustomTypography>
                    <CustomTypography>
                    If you have any questions about these Terms and Conditions, please contact us at:
                    <ul style={{color:SecondaryColor}}>
                        <li><b style={{color:SecondaryColor}}>Email:</b> zealousartistvarshi@gmail.com</li>
                    </ul>
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
            </Grid>
        </Container>)}
    </div>)
}

