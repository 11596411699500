import Compressor from 'compressorjs';
import React, { useContext, useEffect, useState } from 'react';
import CustomContainer from '../../Reusable/CustomContainer';
import { Box, Button, Card, CardMedia, Grid, MenuItem } from '@mui/material';
import CustomTypography from '../../Reusable/CustomTypography';
import { PrimaryColor, SecondaryColor } from '../../Reusable/CustomThemes';
import CustomTextField from '../../Reusable/CustomTextfield';
import { boxStyle } from '../../Reusable/CustomStyles';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { SnackbarContext } from '../../components/UserContext';
import { Loading } from "../../components/Loading";

export const EditMetaData = () => {
  const [image, setImage] = useState([])
  const [err, setErr] = useState(0);
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [wait, setWait] = useState(true);
  const [oldImg, setOldImg] = useState("")

  const [seoData, setSeoData] = useState({
    seo_title: '',
    seo_desc: '',
    seo_keyword: '',
    seo_page:''
  });

  useEffect(() => {
    const fetchCategoryData = async () => {
      const formData = new FormData();
      formData.append('id', id);

      try {
        const response = await axios.post('/admin/get_single_meta', formData,  );
        if (response.data.status) {
          const seo_data = response.data.data;
          setSeoData({
            seo_title: seo_data.meta_title,
            seo_desc: seo_data.meta_desc,
            seo_keyword: seo_data.meta_keywords,
            seo_page: seo_data.meta_page,  
          });
          
          setWait(false)
        }  
      } catch (error) {
        console.error('Error fetching category:', error);
        setSnack({
          message: 'Error fetching category. Please try again.',
          type: 'error',
          open: true,
        });
      }
    };

    fetchCategoryData();
  }, [ ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeoData({ ...seoData, [name]: value });
  };

  

 
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErr(0);

    if (seoData.seo_title === "") {
      setErr(1);
      setSnack({
        message: "Please Enter SEO Title...",
        type: "error",
        open: true,
      });
      return;
    }

    if (seoData.seo_keyword === "") {
      setErr(2);
      setSnack({
        message: "Please Enter Seo Keywords...",
        type: "error",
        open: true,
      });
      return;
    }

    if (seoData.seo_desc === "") {
        setErr(3);
        setSnack({
          message: "Please Enter Seo Description...",
          type: "error",
          open: true,
        });
        return;
      }

     

    setWait(true);

    const formData = new FormData();
    formData.append('id', id);

    formData.append('title', seoData.seo_title);
    formData.append('keywords', seoData.seo_keyword);
    formData.append('desc', seoData.seo_desc);
     

    try {
      const response = await axios.post("/admin/update_meta_info", formData);
      if (response.data.status === true) {
        setSnack({
          message: response.data.msg,
          type: "success",
          open: true,
        });
        setWait(false);
        navigate("/admin_dashboard/all_metadata");
      } else {
        setSnack({
          message: response.data.msg,
          type: "error",
          open: true,
        });
        setWait(false);
      }
    } catch (error) {
      console.error('Error updating category:', error);
      setSnack({
        message: "Error updating category. Please try again.",
        type: "error",
        open: true,
      });
      setWait(false);
    }
  };

  return (
    <div>
      {wait ? (
        <Loading />
      ) : (
        <CustomContainer maxWidth="md"  m={1} p={3}>
         
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} textAlign="center">
                <CustomTypography variant="h5" style={{color:SecondaryColor }} gutterBottom>
              <span style={{color:SecondaryColor,textTransform:'uppercase'}}>Edit Metadata for - {seoData.seo_page}</span>
            </CustomTypography>
            <hr style={{color:SecondaryColor}}/>
            <br/>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="seo_title"
                    value={seoData.seo_title}
                    label="Seo Title *"
                    fullWidth
                    onChange={handleChange}
                    error={err === 1}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <CustomTextField
                    multiline
                    rows={4}
                    name="seo_keyword"
                    value={seoData.seo_keyword}
                    label="SEO Keywords *"
                    fullWidth
                    onChange={handleChange}
                    error={err === 2}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CustomTextField
                    multiline
                    rows={4}
                    name="seo_desc"
                    value={seoData.seo_desc}
                    label="SEO Description *"
                    fullWidth
                    onChange={handleChange}
                    error={err === 3}
                  />
                </Grid>

                
              


                <Grid item xs={12} textAlign="center">
                <br/><br/>
                  <Button
                    variant="contained"
                    disabled={wait}
                    type="submit"
                    style={{
                      backgroundColor: wait ? PrimaryColor : SecondaryColor,
                      width: "60%",
                      color: wait ? SecondaryColor : PrimaryColor,
                    }}
                  >
                    {wait ? "Please Wait...." : (<>UPDATE METADATA</>)}
                  </Button>
                </Grid>
              </Grid>
            </form>
          
        </CustomContainer>
      )}
    </div>
  );
};
