import {useState, useEffect, useRef, useContext} from 'react'


 
import {Grid, Container,Typography, FormLabel, Button} from '@mui/material'

import CustomTypography from '../Reusable/CustomTypography'; 

import CustomTextField from '../Reusable/CustomTextfield';

import CustomButton from '../Reusable/CustomButton';

import {SnackbarContext} from "../components/UserContext";

import {PrimaryColor,SecondaryColor} from '../Reusable/CustomThemes'
import { styled } from '@mui/system';

import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';

import EmailIcon from '@mui/icons-material/Email';

import {Loading} from '../components/Loading'
import { Helmet } from 'react-helmet-async';
 
const CustomHeading = styled(Typography)(({   }) => ({
    position: 'relative',
    color:SecondaryColor,padding:"15px",
    display: 'inline-block',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: '50%',
      borderColor: SecondaryColor,
      borderStyle: 'solid',
    },
    '&:before': {
      top: 0,
      left: 0,
      borderTopWidth: '5px',
      borderLeftWidth: '5px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
    },
    '&:after': {
      bottom: 0,
      right: 0,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: '5px',
      borderRightWidth: '5px',
    },
  }));


export const Contact=()=>{


  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const mobileRef = useRef(null);
  const subjectRef = useRef(null);
  const messageRef = useRef(null);

  const { setSnack } = useContext(SnackbarContext);


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mob, setMob] = useState("");
  const [subj, setSubj] = useState("");
  const [msg, setMsg] = useState("");
  const [err, setErr] = useState(0);
  const [disablebutton, setDisablebutton] = useState(false);


  const [isLoading, setIsLoading] = useState(true);
  const [metaTitle,setMetaTitle] = useState("");
  const [metaKeywords,setMetaKeywords] = useState("");
  const [metaDesc,setMetaDesc] = useState("");


  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const getData=()=>{
    axios.get("/user/get_contact_data"   ).then((res) => {
      if (res.data.status) {
        setMetaTitle(res.data.metadata.meta_title)
        setMetaDesc(res.data.metadata.meta_desc)
        setMetaKeywords(res.data.metadata.meta_keywords)
        setIsLoading(false)
        handleClick();
      } 
    });
  }


  useEffect(()=>{
    getData();
  },[])

  const onSubmit = () => {
    setErr(0);
    if (name == "") {
      setErr(1);
       console.log(nameRef.current,'Chethan')
      if (nameRef.current) {
          nameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      setSnack({
        message: "Please Enter Your Name...",
        type: "error",
        open: true,
      });
    } else if (!email.includes("@")) {
      setErr(2);
      if (emailRef.current) {
        emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
       
      setSnack({
        message: "Please Enter a Valid Email-Id...",
        type: "error",
        open: true,
      });
    } else if (mob.length > 15 || mob.length < 9 || /[a-zA-Z]/.test(mob)) {
      setErr(3);
      if (mobileRef.current) {
        mobileRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      setSnack({
        message: "Please Enter a Valid Mobile Number...",
        type: "error",
        open: true,
      });
    } else if (subj == "") {
      setErr(4);
      if (subjectRef.current) {
        subjectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
       
      setSnack({
        message: "Please Enter Your Subject...",
        type: "error",
        open: true,
      });
    } else if (msg == "") {
      setErr(5);
      if (messageRef.current) {
        messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
       
      setSnack({
        message: "Please Enter Your Message...",
        type: "error",
        open: true,
      });
    } else {
       
      setSnack({
        message: "Please Wait...",
        type: "warning",
        open: true,
      });
      setDisablebutton(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mob", mob);
      formData.append("sub", subj);
      formData.append("msg", msg);
      axios.post("/user/submitt_contact_us", formData,  ).then((res) => {
        if (res.data.status) {
          setDisablebutton(false);
           
          setSnack({
            message:  res.data.msg,
            type: "success",
            open: true,
          });
        } else {
          setDisablebutton(false);
          
          setSnack({
            message: "Something went wrong... try later.",
            type: "error",
            open: true,
          });
        }
      });
    }
  };

    return (<div>
         {isLoading ? (
      <Loading />
    ) : (<Container>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metaKeywords} />

      </Helmet>
            <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            >
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                <CustomHeading variant="h4">
                Contact The Artist
                </CustomHeading>
                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  // spacing={6}
                >
               

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={4}
                    >
                      <Grid item xs={12} sm={8} md={12} lg={12} xl={12}  >
                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{
                            textAlign: "center",
                            color: SecondaryColor,
                          }}
                        >
                         Get in Touch with the Artist
                        </Typography>
                        <FormLabel
                        className="article_desc"
                          style={{
                            lineHeight: "1.5",
                            textAlign: "left !important",
                            color: SecondaryColor,
                          }}
                        >
                          Reach out for any inquiries.
                        </FormLabel>
                        <br/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <CustomTextField
                          ref={nameRef} 
                          id="name"
                          label="Name"
                          // variant="filled"
                          error={err === 1 && true}
                          value={name}
                          size="small"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          required
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomTextField
                          id="email"
                          label="Email"
                          ref={emailRef}
                          // variant="filled"
                          error={err === 2 && true}
                          value={email}
                          size="small"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          required
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomTextField
                          id="mobile"
                          label="Mobile"
                          ref={mobileRef}
                          // variant="filled"
                          error={err === 3 && true}
                          // type="number"
                          value={mob}
                          size="small"
                          onChange={(e) => {
                            setMob(e.target.value);
                          }}
                          required
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomTextField
                          id="subject"
                          ref={subjectRef}
                          label="Enquiry for"
                          // variant="filled"
                          error={err === 4 && true}
                          value={subj}
                          size="small"
                          onChange={(e) => {
                            setSubj(e.target.value);
                          }}
                          required
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomTextField
                          ref={messageRef}
                          id="message"
                          label="Message"
                          // variant="filled"
                          error={err === 5 && true}
                          value={msg}
                           
                          multiline
                          rows={3}
                          onChange={(e) => {
                            setMsg(e.target.value);
                          }}
                          required
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="text-center"
                      >
                        <Button
                          onClick={onSubmit}
                          variant="contained"
                          color="primary"
                          disabled={disablebutton}
                          style={{
                            backgroundColor: disablebutton ? PrimaryColor : SecondaryColor,
                            width: "60%",
                            color: disablebutton ? SecondaryColor : PrimaryColor,
                          }}
                        >
                          <SendIcon /> &nbsp;&nbsp;
                          {disablebutton ? "Please wait.." : "Submit Details"}
                        </Button>
                      </Grid>
                </Grid>  
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      container      
                       
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                      // className="article_desc"
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography
                          variant="body1"
                          
                          style={{
                            
                            color: SecondaryColor,
                          }}
                        >
                          <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item>
                                <EmailIcon />
                                </Grid>
                                <Grid item>
                                  
                                <span style={{marginBottom:"5px"}}>zealousartistvarshi@gmail.com</span>
                                </Grid>
                          </Grid>
                          
                      </Typography>
                         
                       </Grid>
                    </Grid>
                </Grid>
               </Grid>
               </Grid>
            </Grid>
         </Container>)}       
    </div>)
}

