import {useState, useEffect} from 'react'

import {Grid, Container,Typography, MenuItem} from '@mui/material'

import CustomTypography from '../Reusable/CustomTypography'; 

import {PrimaryColor,SecondaryColor} from '../Reusable/CustomThemes'
import { styled } from '@mui/system';

import {useParams, useNavigate} from 'react-router-dom'

import {Loading} from '../components/Loading'

import CustomTextField from '../Reusable/CustomTextfield'

import ArtCard from './components/ArtCard'

import axios from 'axios';
import { Helmet } from 'react-helmet-async';


const CustomHeading = styled(Typography)(({   }) => ({
    position: 'relative',
    color:SecondaryColor,padding:"15px",
    display: 'inline-block',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: '50%',
      borderColor: SecondaryColor,
      borderStyle: 'solid',
    },
    '&:before': {
      top: 0,
      left: 0,
      borderTopWidth: '5px',
      borderLeftWidth: '5px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
    },
    '&:after': {
      bottom: 0,
      right: 0,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: '5px',
      borderRightWidth: '5px',
    },
  }));


export const MyArts=()=>{

    var params = useParams()

    const history=useNavigate();

    const [cat,setCat] = useState(params.cat);
    const [subCat,setSubCat] = useState(params.subcat);
    const [sort,setSort] = useState(params.sort);

    const [results, setResults] = useState([])


    const [isLoading, setIsLoading] =useState(true)


    const [cats,setCats] = useState([]);
    const [subCats,setSubCats] = useState([]);


    const [metaTitle,setMetaTitle] = useState("");
    const [metaKeywords,setMetaKeywords] = useState("");
    const [metaDesc,setMetaDesc] = useState("");

    const handleClick = () => {
      const anchor = document.querySelector("#back-to-top-anchor");
  
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

    const getData=async()=>{
      setIsLoading(true)
      const formData = new FormData();
      formData.append("cat", cat);
      formData.append("subcat", subCat);
      formData.append("sort", sort);
       
      await axios.post("/user/all_arts", formData,  ).then((res) => {
        if (res.data.status) {
           handleClick() 
           setCats(res.data.cats)
           setSubCats(res.data.subcats)
           setResults(res.data.data)
           setMetaTitle(res.data.metadata.meta_title)
           setMetaDesc(res.data.metadata.meta_desc)
           setMetaKeywords(res.data.metadata.meta_keywords)
           setIsLoading(false)
        }  
      });
    }


    useEffect(()=>{
      getData();
    },[params])


    return (<div>
        {isLoading ? (
      <Loading />
    ) : (<Container>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metaKeywords} />

      </Helmet>
            <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            >
                <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>
                    <CustomHeading variant="h4">
                    Art Showcase
                    </CustomHeading>
                    </Grid>
                    <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>

                </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs={12} sm={6} md={4}>
                          <CustomTextField
                            name="cat_ids"
                            value={cat}
                            label="Category"
                             
                            select
                            fullWidth
                            onChange={(e)=>{
                              setCat(e.target.value)
                              setSubCat('all')
                              history("/art_showcase/"+e.target.value+"/"+subCat+"/"+sort);
                            }}
                          >
                            <MenuItem value="all">All</MenuItem>
                            {cats.map((cat) => (
                            <MenuItem key={cat.cat_id} value={cat.cat_id}>
                              {cat.cat_name}  
                            </MenuItem>
                          ))}
                          </CustomTextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CustomTextField
                            name="cat_ids"
                            value={subCat}
                            label="Subcategory"
                             
                            select
                            fullWidth
                            onChange={(e)=>{
                              setSubCat(e.target.value)
                              history("/art_showcase/"+cat+"/"+e.target.value+"/"+sort);
                            }}
                          >
                            <MenuItem value="all">All</MenuItem>
                            {subCats.map((subcat) => {
                              if(cat.toString()===subcat.cat_ids.toString()){
                            return <MenuItem key={subcat.subcat_id} value={subcat.subcat_id}>
                              {subcat.subcat_name}  
                            </MenuItem>
                              }
                          })}
                          </CustomTextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CustomTextField
                            name="cat_ids"
                            value={sort}
                            label="Sort"
                             
                            select
                            fullWidth
                            onChange={(e)=>{
                              setSort(e.target.value)
                              history("/art_showcase/"+cat+"/"+subCat+"/"+e.target.value);
                            }}
                          >
                            <MenuItem value="latest">Latest</MenuItem>
                            <MenuItem value="oldest">Oldest</MenuItem>
                          </CustomTextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={4}
                        >
                            {results.map((result) => (
                             <Grid item xs={12} sm={6} md={4} lg={3}>
                                <ArtCard  id={result.art_id} 
                                          image={"/static/arts/"+result.art_image}
                                          title={result.art_title}
                                          category={result.cat_name}
                                          subcategory={result.subcat_name}
                                          postedDate={result.art_posted_date}/>
                             </Grid>
                          ))}
                        </Grid>
                    </Grid>
              </Grid>
              </Container> ) }    
    </div>)
}

