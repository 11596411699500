import { Box, Grid, Toolbar } from "@mui/material";
import React from "react";
import { AdminRoutes } from "./adminRoutes";
import { LeftDrawer } from "./LeftDrawer";

export const AdminDashboard = () => {
  return (
    <div>
      <Box>
        <br/>
        <br/>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item   md={4} lg={3} xl={3}>
            <LeftDrawer />
          </Grid>

          <Grid item xs={12} md={8} lg={9} xl={9}>
            {/* <Toolbar /> */}
            <AdminRoutes />
          </Grid>
        </Grid>
        <br/>
         
      </Box>
    </div>
  );
};
