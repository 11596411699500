import React, { useContext, useEffect, useState } from "react";
import { AdminContext, SnackbarContext } from "../components/UserContext";
import CustomContainer from "../Reusable/CustomContainer";
import CustomBox from "../Reusable/CustomBox";
import CustomTextField from "../Reusable/CustomTextfield";
import { Grid } from "@mui/material";
import CustomButton from "../Reusable/CustomButton";
import CustomTypography from "../Reusable/CustomTypography";
import axios from "axios";
import { Loading } from "../components/Loading";
import { boxStyle } from "../Reusable/CustomStyles";
import { PrimaryColor, SecondaryColor } from "../Reusable/CustomThemes";

export const UpdateProfile = () => {
  // Accessing admin context for current user details
  const { admin } = useContext(AdminContext);
  // State variables to manage form data, errors, loading state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [err, setErr] = useState(0);
  const [wait, setWait] = useState(false);
  const [loading,setLoading]=useState(true)
  // Accessing snackbar context to display messages
 const {setSnack}=useContext(SnackbarContext)
 // Fetching initial data on component mount
  useEffect(()=>{
    getData()
  },[])
  // Function to fetch user data from the server
  const getData=async()=>{
    setLoading(true)
    const fromData=new FormData()
    fromData.append("admin_id",admin.id)
    await axios.post("/admin/get_single_admin_data",fromData).then((res)=>{
      if(res.data.status===true){
        
        setName(res.data.data.admin_name)
        setEmail(res.data.data.admin_email)
        setMobile(res.data.data.admin_mobile)
        setLoading(false)
      }
    })
  }
  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErr(0)
    if(name===""){
      setErr(1)
      setSnack({
        message: "Please Enter Name...",
        type: "error",
        open: true,
      });
    }
    else if(!String(email).includes("@")){
      setErr(2);
      setSnack({
        message: "Please Enter Valid Email Id...",
        type: "error",
        open: true,
      });
    }
    else if (String(mobile).length !== 10) {
      setErr(3);
      setSnack({
        message: "Please Enter Valid Mobile Number...",
        type: "error",
        open: true,
      });
    }
    else{
    setWait(true);
    setLoading(true)
      const formdata = new FormData();
      formdata.append("admin_id", admin.id)
      formdata.append("uname", name);
      formdata.append("email", email);
      formdata.append("mobile", mobile);
      await axios.post("/admin/update_admin_details", formdata).then((res) => {
        if (res.data.status === true) {
            setSnack({
              message: res.data.msg,
              type: "success",
              open: true,
            });
            setWait(false);
            setLoading(false)
            getData();// Refreshing data after successful update
          }
          else {
            setSnack({
              message: res.data.msg,
              type: "error",
              open: true,
            });
            setWait(false);
            setLoading(false)
          }
      })
    }
  };
  return (
    <div>
      {loading?<Loading />:
      <CustomContainer maxWidth="md"  m={1} p={3}>
        
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12}>
              <CustomTypography variant="h5" style={{color:SecondaryColor }} gutterBottom>
              <span style={{color:SecondaryColor,textTransform:'uppercase'}}>Update Details</span>
            </CustomTypography>
            <hr style={{color:SecondaryColor}}/>
            <br/>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Name"
                  name={"name"}
                  value={name}
                  fullWidth
                  error={err === 1 && true}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Email"
                  name={"email"}
                  fullWidth
                  value={email}
                  error={err === 2 && true}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Mobile"
                  name={"mobile"}
                  value={mobile}
                  fullWidth
                  error={err === 3 && true}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} m={5} textAlign="center">
                <CustomButton
                  variant="contained"
                  type="submit"
                  disabled={wait}
                  label={wait ? "Please Wait...." : "Update Profile"}
                  sx={{
                    width: "60%",
                    backgroundColor: wait ? PrimaryColor : SecondaryColor,
                    color: wait ? SecondaryColor : PrimaryColor,
                    "&:hover": { backgroundColor: wait ? "wait" : SecondaryColor },
                  }}
                />
              </Grid>
            </Grid>
          </form>
      
      </CustomContainer>
}
    </div>
  );
};
