import PlayCircleIcon from '@mui/icons-material/PlayCircle'; import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AdminContext } from "../components/UserContext";
import axios from "axios";
import { PrimaryColor, SecondaryColor } from "../Reusable/CustomThemes";
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from '@mui/icons-material/Home';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import QuizIcon from '@mui/icons-material/Quiz';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import HistoryIcon from '@mui/icons-material/History';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PaletteIcon from '@mui/icons-material/Palette';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import FilterIcon from '@mui/icons-material/Filter';
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@mui/icons-material/Email';

export const LeftDrawer = () => {
  // Hook to navigate to different routes
  let history = useNavigate();
  // Accessing admin context to handle logout action
  const { setAdmin } = useContext(AdminContext);
  const [open, setOpen] = useState(null);

  const handleClick = (id) => {
    if (open === id) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };

  const handleDrawerClose = (id) => {
    setOpen(false);
  };

  // Array of pages with their titles and links
  const pages = [
    {
      id: "1",
      title: "Categories",
      link: "all_categories",
      icon: <PermMediaIcon fontSize="small" />,
    },
    {
      id: "2",
      title: "Sub Categories",
      link: "all_sub_categories",
      icon: <FilterIcon fontSize="small" />
    },
    {
      id: "2",
      title: "Arts",
      link: "all_arts",
      icon: <PaletteIcon fontSize="small" />
    },
    {
      id: "6",
      title: "SEO Meta Data",
      link: "all_metadata",
      icon:<SearchIcon fontSize="small"/>
    },
    {
      id: "7",
      title: "Contact History",
      link: "contact_history/all",
      icon:<HistoryIcon fontSize="small"/>
    },
    {
      id: "8",
      title: "Subscribers History",
      link: "subscribers_history/all",
      icon:<EmailIcon fontSize="small"/>
    },
    {
      id: "4",
      title: "Update Details",
      link: "update_profile",
      icon: <EditIcon fontSize="small" />
    },
    {
      id: "5",
      title: "Update Password",
      link: "update_password",
      icon: <LockIcon fontSize="small" />,
    },
  ];
  // Function to handle logout action
  const handleLogout = () => {
    axios.post("/admin/logout").then((res) => {
      if (res.data.status === true) {
        setAdmin(null);
      }
    });
  };
  return (
    <div>
      <Box>
        <List sx={{ m: "5px" }}>
          <ListItem sx={{ p: 1 }}>
            <ListItemButton
              sx={{
                height: 35,
                p: 2,
                backgroundColor: SecondaryColor,
                borderRadius: 2,
                "&:hover": {
                  backgroundColor: PrimaryColor,
                },
              }}
              onClick={() => history("/admin_dashboard")}
            >
              <ListItemText sx={{ color: PrimaryColor , "&:hover": {color: SecondaryColor, }}}>
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                  <HomeIcon fontSize='small'/>
                  </Grid>
                   
                  <Grid item>
                  Dashboard
                  </Grid>
              </Grid>
                
              </ListItemText>
            </ListItemButton>
          </ListItem>
          {pages.map((page) => (
            <>
              {page.subtitle ? (<>
                <ListItem key={page.id} sx={{ p: 1 }}>
                  <ListItemButton
                    sx={{
                      height: 35,
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: SecondaryColor,
                      "&:hover": {
                        backgroundColor: PrimaryColor,
                        color: "#ffff",
                      },
                    }}
                    component={Link}
                    onClick={() => handleClick(page.id)}

                  >

                    <ListItemText sx={{ color: PrimaryColor }}>
                      {page.icon} &nbsp;&nbsp; {page.title}
                    </ListItemText>
                    <ListItemIcon>
                      {open === page.id ? (
                        <ExpandLess sx={{ color: "white", m: "13px" }} />
                      ) : (
                        <ExpandMore sx={{ color: "white", m: "13px" }} />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
                <Collapse in={open === page.id}
                  timeout="auto"
                  unmountOnExit>
                  <List>
                    {page.subtitle.map((i) => (<>
                      <ListItem key={i.id} sx={{ p: 1 }}>
                        <ListItemButton
                          sx={{
                            height: 35,
                            p: 2,
                            ml: 2,
                            borderRadius: 2,
                            backgroundColor: SecondaryColor,
                            "&:hover": {
                              backgroundColor: PrimaryColor,
                              color: "#ffff",
                            },
                          }}
                          component={Link}
                          to={`/admin_dashboard/${i.link}`}

                        >

                          <ListItemText sx={{ color: PrimaryColor }}>
                            {i.icon} &nbsp;&nbsp;  {i.title}
                          </ListItemText>

                        </ListItemButton>
                      </ListItem>
                    </>))}
                  </List>
                </Collapse>
              </>) : <>
                <ListItem key={page.id} sx={{ p: 1 }}>
                  <ListItemButton
                    sx={{
                      height: 35,
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: SecondaryColor,
                      "&:hover": {
                        backgroundColor: PrimaryColor,
                        color: SecondaryColor,
                      },
                    }}
                    component={Link}
                    to={`/admin_dashboard/${page.link}`}

                  >

                    <ListItemText sx={{ color: PrimaryColor,  "&:hover": {color: SecondaryColor, }}}>
                    <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                  {page.icon}
                  </Grid>
                   
                  <Grid item>
                  {page.title}
                  </Grid>
              </Grid>
                        
                    </ListItemText>

                  </ListItemButton>
                </ListItem>
              </>}



            </>
          ))}


          <ListItem sx={{ p: 1 }}>
            <ListItemButton
              sx={{
                height: 35,
                p: 2,
                borderRadius: 2,
                backgroundColor: SecondaryColor,
                "&:hover": {
                  backgroundColor: PrimaryColor,
                  color: "#ffff",
                },
              }}
              onClick={() => {
                handleLogout();
              }}
            >
              <ListItemText sx={{ color: PrimaryColor , "&:hover": {color: SecondaryColor, }}}>
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                  <LogoutIcon  fontSize="small"/>
                  </Grid>
                   
                  <Grid item>
                  Logout
                  </Grid>
              </Grid>
                
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </div>
  );
};