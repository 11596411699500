import {useState, useEffect} from 'react'


 
import {Grid, Container,Typography} from '@mui/material'

import CustomTypography from '../Reusable/CustomTypography'; 

import {useParams, useNavigate} from 'react-router-dom'

import {PrimaryColor,SecondaryColor} from '../Reusable/CustomThemes'
import { styled } from '@mui/system';
import {Loading} from '../components/Loading'
import ArtCarousel from './components/ArtCarousel'
import ArtDescription from './components/ArtDescription'
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { SubscribeLetter} from './components/SubsribeLetter'

const CustomHeading = styled(Typography)(({   }) => ({
    position: 'relative',
    color:SecondaryColor,padding:"15px",
    display: 'inline-block',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: '50%',
      borderColor: SecondaryColor,
      borderStyle: 'solid',
    },
    '&:before': {
      top: 0,
      left: 0,
      borderTopWidth: '5px',
      borderLeftWidth: '5px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
    },
    '&:after': {
      bottom: 0,
      right: 0,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: '5px',
      borderRightWidth: '5px',
    },
  }));


export const ArtDetails=()=>{

    var params = useParams()


    const history=useNavigate();

    const [images, setImages] = useState([])

    const [artDetails, setArtDetails] = useState()

    const [metaTitle,setMetaTitle] = useState("");

    const [isLoading, setIsLoading] =useState(true)


    const handleClick = () => {
      const anchor = document.querySelector("#back-to-top-anchor");
  
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

    const getData=async()=>{
        setIsLoading(true)
        const formData = new FormData();
        formData.append("art_id", params.id);
         
         
        await axios.post("/user/get_single_art", formData,  ).then((res) => {
          if (res.data.status) {
             setImages(res.data.images)
             setArtDetails(res.data.data)
             setMetaTitle(res.data.meta_title)
             setIsLoading(false)
             handleClick()
          }  
        });
    }

    useEffect(()=>{
        getData()
    },[])


    useEffect(()=>{
        // getData();
    },[params])

    return (<div>
         {isLoading ? (
      <Loading />
    ) : (<Container>
      <Helmet>
        <title>{metaTitle}</title>
         

      </Helmet>
            <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            >
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                <CustomHeading variant="h4">
                Art Details
                </CustomHeading>
                </Grid>
                <Grid item xs={12}>
                   
                </Grid>
                <Grid item xs={12}>
                   
                </Grid>
                <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                    >
                    <Grid item xs={12} sm={12} md={6} style={{textAlign:"start"}}>
                        {/* here i want to show Images in Carousels. */}
                        <ArtCarousel images={images} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        {/* Here i want to show all Art details like -> art_title, category_name, subcategory_name, description, owner name and Created Date*/}
                        <ArtDescription title={artDetails.art_title} category={artDetails.cat_name} subcategory={artDetails.subcat_name} description={artDetails.art_desc} ownerName={artDetails.art_owner_name} createdDate={artDetails.art_posted_date} />
                    </Grid>    
                </Grid>
                </Grid>
                <Grid item xs={12}>
                      <br/>
                      <br/>
                    </Grid>
                    <Grid item xs={12}>
                      <SubscribeLetter />
                    </Grid>
                    <Grid item xs={12}>
                      <br/>
                    </Grid>
            </Grid>
         </Container>  )}     
    </div>)
}

