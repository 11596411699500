import { Grid,Typography,Button } from "@mui/material"

import {PrimaryColor,SecondaryColor} from '../../Reusable/CustomThemes'

import CustomTextField from '../../Reusable/CustomTextfield';

import {useState,useRef,useContext} from 'react'

import {SnackbarContext} from '../../components/UserContext'

import axios from 'axios';

import EmailIcon from '@mui/icons-material/Email';

export const SubscribeLetter=()=>{

    const nameRef = useRef(null);
    const emailRef = useRef(null);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const { setSnack } = useContext(SnackbarContext);

    const [err, setErr] = useState(0);
    const [disablebutton, setDisablebutton] = useState(false);

    const onSubmit = () => {
        setErr(0);
        if (name == "") {
          setErr(1);
           
          if (nameRef.current) {
              nameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
          
          setSnack({
            message: "Please Enter Your Name...",
            type: "error",
            open: true,
          });
        } else if (!email.includes("@")) {
          setErr(2);
          if (emailRef.current) {
            emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
           
          setSnack({
            message: "Please Enter a Valid Email-Id...",
            type: "error",
            open: true,
          });
        }else{
            setSnack({
                message: "Please Wait...",
                type: "warning",
                open: true,
              });
              setDisablebutton(true);
              const formData = new FormData();
              formData.append("name", name);
              formData.append("email", email);

              axios.post("/user/subscribe_news", formData,  ).then((res) => {
                if (res.data.status) {
                  setDisablebutton(false);
                   
                  setSnack({
                    message:  res.data.msg,
                    type: "success",
                    open: true,
                  });
                  setEmail("")
                  setName("")
                } else {
                  setDisablebutton(false);
                  setSnack({
                    message: res.data.msg,
                    type: "warning",
                    open: true,
                  });
                }
              });
        }

    }

    return (<Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
                    >
                      <Grid item xs={12} style={{marginBottom:'20px'}}>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                textTransform:'capitalize',
                                color: SecondaryColor,
                              }}
                            >
                              <strong>Unlock Artistic Discoveries with Our Newsletter</strong><br/>
                            </Typography>
                            <hr/>
                      </Grid>
                      <Grid item xs={12}  sm={6} md={4}>
                      <CustomTextField
                          ref={nameRef} 
                          id="name"
                          label="Name"
                          // variant="filled"
                          error={err === 1 && true}
                          value={name}
                          size="small"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          required
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          />
                      </Grid>
                      <Grid item xs={12}  sm={6} md={4}>
                      <CustomTextField
                          id="email"
                          label="Email"
                          ref={emailRef}
                          // variant="filled"
                          error={err === 2 && true}
                          value={email}
                          size="small"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          required
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12}  sm={6} md={4} className="text-center">
                      <Button
                          onClick={onSubmit}
                          variant="contained"
                          color="primary"
                          disabled={disablebutton}
                          style={{
                            backgroundColor: disablebutton ? PrimaryColor : SecondaryColor,
                            width: "60%",
                            color: disablebutton ? SecondaryColor : PrimaryColor,
                          }}
                        >
                          <EmailIcon /> &nbsp;&nbsp;
                          {disablebutton ? "Please wait.." : "Subscribe"}
                        </Button>
                      </Grid>
                    </Grid>)
}