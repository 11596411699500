import React from 'react';
import {TextField} from "@mui/material";
import '../App.css'

import {PrimaryColor,SecondaryColor} from '../Reusable/CustomThemes'


const CustomTextField = React.forwardRef(({ label, ...props }, ref) => {
  return (
    <TextField
      ref={ref}  // Ensure ref is being set here
      label={label}
      variant='standard'
      
      {...props}
      InputProps={{
        ...props.InputProps,
        // Apply styles to the input field itself
        onFocus: (event) => {
          // Change input text color and underline color on focus
          event.target.style.color = SecondaryColor; 
          event.target.parentNode.parentNode.style.borderColor = SecondaryColor; // Change the underline color
        },
        onBlur: (event) => {
          // Reset input text color and underline color on blur
          event.target.style.color = SecondaryColor; 
          event.target.parentNode.parentNode.style.borderColor = SecondaryColor; // Reset the underline color
        }
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        shrink: true,
        style: {
          color: SecondaryColor // Default color for the label
        },
        // Apply styles to the label
        onFocus: (event) => {
          // Change label color on focus
          event.target.style.color = SecondaryColor;
        },
        onBlur: (event) => {
          // Reset label color on blur
          event.target.style.color = SecondaryColor;
        }
      }}
    />
  );
});

export default CustomTextField;