import React from 'react'
import { PrimaryColor, SecondaryColor } from '../Reusable/CustomThemes'
import {Typography} from '@mui/material' 


export const Home=()=> {
  return (
    <div>
       
      <Typography variant="h5" style={{color:SecondaryColor }} gutterBottom>
              <span style={{color:SecondaryColor,textTransform:'uppercase'}}>Welcome To Varshi's Art Gallery</span>
            </Typography>
    </div>
  )
}
