import { Button, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomContainer from "../../Reusable/CustomContainer";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CustomBox from "../../Reusable/CustomBox";
import EditIcon from "@mui/icons-material/Edit";
import CustomTypography from "../../Reusable/CustomTypography";
import { DatePicker, Space } from "antd";
import { Loading } from "../../components/Loading";
import { PrimaryColor, SecondaryColor } from "../../Reusable/CustomThemes";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LockIcon from "@mui/icons-material/Lock";
 

import '../../App.css'
var moment = require("moment-timezone");

export const AllMetaData = () => {
    
    const navigate = useNavigate();
    const [userData,setUserData]=useState([])
    const [loading,setLoading]=useState(true)
     
    const [page, setPage] = useState(10);
    const dateFormat = "YYYY-MM-DD";
    let history=useNavigate()

    useEffect(()=>{
        
        getData()
    
    },[ ])

    const getData=async()=>{

         
        await axios.post("/admin/get_all_meta",  ).then((res) => {
          if ( res.data.status === true) {
            setUserData(res.data.data);
            setLoading(false);
          }
        });
  
    }

      // Column definitions for the data grid
  const columns = [
    { field: "meta_id", headerName: "ID", width: 100 },
    { field: "meta_title", headerName: "SEO TITLE", width: 300 },
    { field: "meta_page", headerName: "PAGE", width: 100 },
    {
        field: 'actions',
        headerName: 'EDIT METADATA',
        width: 200,
        renderCell: (params) => (
          <Grid container spacing={1}>
            <Grid item>
              <IconButton
                onClick={() => navigate(`/admin_dashboard/edit_metadata/${params.row.meta_id}`)}
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        )
      },
    { field: "edited_date", headerName: "UPDATED DATE", width: 300 },
     
  ];
 
 
  return (
    <div>
  
   {loading?<Loading />:(
        <CustomContainer maxWidth="lg">
          <CustomTypography variant="h5" style={{color:SecondaryColor }} gutterBottom>
              <span style={{color:SecondaryColor,textTransform:'uppercase'}}>SEO Meta Data</span>
            </CustomTypography>
            <hr style={{color:SecondaryColor}}/>
            <br/>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
             
          </Grid>

        
          <CustomBox
            sx={{
                
              height: "500px",
              width: "100%",
              mt: 2,
            }}
          >
            <DataGrid
              hideFooterSelectedRowCount
              rows={userData}
              columns={columns}
              getRowId={(row) => row.meta_id}
              rowsPerPageOptions={[10, 20, 30]}
              pageSize={page}
              getRowHeight={() => "auto"}
              getRowClassName={() => 'custom-row'}
              sx={{
                ".MuiDataGrid-columnHeaders": {
                  backgroundColor: SecondaryColor,
                  color:PrimaryColor,
                },
                ".MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                ".css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root ": {
                  color:PrimaryColor,
                },
                ".MuiDataGrid-menuIcon": {
                  visibility: "visible",
                  display: "contents",
                },
                /* CSS to center buttons in DataGrid cells */

              }}
            />
          </CustomBox>
        </CustomContainer>
      )
}
    </div>
  );
};
