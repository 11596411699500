import {useState, useEffect} from 'react'


 
import {Grid, Container,Typography} from '@mui/material'

import CustomTypography from '../Reusable/CustomTypography'; 

import {PrimaryColor,SecondaryColor} from '../Reusable/CustomThemes'
import { styled } from '@mui/system';
import axios from 'axios';
import {Loading} from '../components/Loading'
import { Helmet } from 'react-helmet-async';
import { SubscribeLetter } from "./components/SubsribeLetter"


import '../App.css'

const CustomHeading = styled(Typography)(({   }) => ({
    position: 'relative',
    color:SecondaryColor,padding:"15px",
    display: 'inline-block',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: '50%',
      borderColor: SecondaryColor,
      borderStyle: 'solid',
    },
    '&:before': {
      top: 0,
      left: 0,
      borderTopWidth: '5px',
      borderLeftWidth: '5px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
    },
    '&:after': {
      bottom: 0,
      right: 0,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: '5px',
      borderRightWidth: '5px',
    },
  }));


export const About=()=>{

  const [isLoading, setIsLoading] = useState(true);
  const [metaTitle,setMetaTitle] = useState("");
  const [metaKeywords,setMetaKeywords] = useState("");
  const [metaDesc,setMetaDesc] = useState("");


  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const getData=()=>{
    axios.get("/user/get_about_data"   ).then((res) => {
      if (res.data.status) {
        setMetaTitle(res.data.metadata.meta_title)
        setMetaDesc(res.data.metadata.meta_desc)
        setMetaKeywords(res.data.metadata.meta_keywords)
        setIsLoading(false)
        handleClick();
      } 
    });
  }


  useEffect(()=>{
    getData();
  },[])


    return (<div>
        {isLoading ? (
      <Loading />
    ) : (<Container>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metaKeywords} />

      </Helmet>
            <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            >
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                <CustomHeading variant="h4">
                Artist Bio
                </CustomHeading>
                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                  >
                    <Grid item xs={12}>
                      <Typography
                          variant="body2"
                          gutterBottom
                          style={{
                            textAlign: "center",
                            color: SecondaryColor,
                          }}
                        >
                          Welcome to Varshi's Art Gallery World, where creativity knows no bounds. Varshi is a contemporary artist whose work spans various mediums, exploring themes of nature, humanity, the cosmos, and mental health. With a deep passion for art, Varshi's creations are a testament to the power of imagination and the beauty of the human spirit.
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <img src="/static/about/logo_full.png" style={{width:'100%', height:"300px",objectFit:'contain'}}/>
                    </Grid> */}
                    <Grid item xs={12}>
                    <Grid container spacing={10} alignItems="center" justifyContent='center'>
                          <Grid item xs={12} sm={12} md={4}>
                          <div className="custom-image3">
                            <img
                              src="/static/about/painting1.jpg"
                              alt="Artistic Journey"
                              
                              style={{
                                width: '100%',
                                height: '400px',
                                 
                                 
                                objectFit:'contain' 
                              }}
                            />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              <strong>Artistic Journey:</strong><br/>
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              Varshi's artistic journey began at a young age, with an inherent love for drawing and painting. Over the years, this passion evolved into a professional career, with Varshi dedicating countless hours to honing their craft. The journey has been filled with exploration and experimentation, leading to a unique style that captivates and inspires. During the COVID-19 years of 2020 and 2021, Varshi explored her artistry more intensely, creating over 400 works of art.
                            </Typography>
                            <br/><br/>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              <strong>Inspirations:</strong><br/>
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              Nature, emotions, the intricacies of life, and mental health are central to Varshi's work. Each piece is a reflection of personal experiences and a profound connection with the world. The use of vibrant colors, intricate details, and dynamic compositions brings Varshi's vision to life, inviting viewers to embark on a journey of their own.
                            </Typography>
                            <br/><br/>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              <strong>Achievements:</strong><br/>
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              Varshi's work has been featured in numerous exhibitions, both locally and internationally. The artist has received several accolades for their contributions to contemporary art, including prestigious awards for innovation and creativity. Notably, Varshi holds a Guinness World Record for a group painting and recently achieved a Harvard record for completing an acrylic painting on stretched canvas solo in just 3 hours and 30 minutes. Varshi's pieces are held in private collections around the world, a testament to the universal appeal and enduring impact of her art.
                            </Typography>
                          </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                      <br/>
                      <div className="custom-image2">
                      <img   src="/static/about/painting3.jpg" style={{  
                                 width:'100%', height:"300px",objectFit:'contain'}}/>
                                 </div>
                                 <br/>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                    <Grid container spacing={10} alignItems="center" justifyContent='center'>
                          
                          <Grid item xs={12} sm={12} md={8}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              <strong>Philosophy:</strong><br/>
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              For Varshi, art is more than just a visual experience; it is a way of life. Each creation is a dialogue between the artist and the viewer, a means of expressing the inexpressible. Varshi believes in the transformative power of art to heal, inspire, and bring people together. This philosophy is evident in every piece, resonating with a sense of authenticity and profound emotion.
                            </Typography>
                            <br/><br/>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              <strong>Looking Ahead:</strong><br/>
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              As Varshi continues to evolve as an artist, the future holds exciting possibilities. New projects and collaborations are on the horizon, each promising to push the boundaries of creativity. Through dedication and passion, Varshi's Art Gallery remains committed to creating art that speaks to the soul and enriches the human experience.
                            </Typography>
                            <br/><br/>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              <strong>Connect with Varshi:</strong><br/>
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              Stay connected with Varshi's Art Gallery to keep up with the latest creations, exhibitions, and projects. Follow on social media, sign up for the newsletter, or visit the contact page to get in touch. Varshi welcomes art enthusiasts, collectors, and collaborators to join in the journey and explore the world through the lens of art.






                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <div className="custom-image">
                            <img
                               
                              src="/static/about/painting2.jpg"
                              alt="Artistic Journey"
                              style={{
                                width: '100%',
                                height: '400px',
                                objectFit:'contain' 
                                
                              }}
                            />
                            </div>
                          </Grid>
                        </Grid>

                        
                    </Grid>
                    <Grid item xs={12}>
                      <br/>
                    </Grid>
                    <Grid item xs={12}>
                      <SubscribeLetter />
                    </Grid>
                    <Grid item xs={12}>
                      <br/>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
         </Container>)}       
    </div>)
}

