import { AppBar, Toolbar, Drawer,List,ListItem,ListItemText, Divider } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { PrimaryColor, SecondaryColor } from '../Reusable/CustomThemes'
import {Link} from 'react-router-dom';

import {Typography,Grid, Hidden, Button, Container} from '@mui/material' 


import MenuIcon from '@mui/icons-material/Menu';
export const Navbar=()=> {


  const [elevate, setElevate] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setElevate(true);
    } else {
      setElevate(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

    
  return (
     
      <AppBar position='sticky' id="back-to-top-anchor" sx={
        { backgroundColor : "white" , color : SecondaryColor,boxShadow:elevate ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none' }
        }>
          <Container>
        <Toolbar>
          {/* <img src={logo} width={50} height={50} style={{border:"2px solid white",borderRadius:"50px"}} /> */}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link to="/" className="linkstyle">
            <Grid item>
                    <img
                        src="/static/logo.png"
                        
                        style={{
                          height: "150px",
                          // width: "100px",
                          // borderRadius: "50%",
                        }}
                      />
            </Grid>
            </Link>
            <Grid item>
              <Hidden mdUp>
                <Button onClick={toggleDrawer("bottom", true)}>
                  <MenuIcon style={{ color: SecondaryColor }} />
                </Button>
                <Drawer
                  anchor={"bottom"}
                  open={state["bottom"]}
                  onClose={toggleDrawer("bottom", false)}
                >
                  <List component="nav" aria-label="contacts">
                    <ListItem
                      button
                      component={Link}
                      to={"/artist_bio"}
                      onClick={toggleDrawer("bottom", false)}
                    >
                      <ListItemText primary="Artist Bio" style={{color:SecondaryColor}}/>
                    </ListItem>
                    <Divider  style={{color:SecondaryColor}}/>
                  </List>
                  <List component="nav" aria-label="contacts">
                    <ListItem
                      button
                      component={Link}
                      to={"/art_showcase/all/all/latest"}
                      onClick={toggleDrawer("bottom", false)}
                    >
                      <ListItemText primary="Art Showcase" style={{color:SecondaryColor}}/>
                    </ListItem>
                    <Divider  style={{color:SecondaryColor}}/>
                  </List>
                  <List component="nav" aria-label="contacts">
                    <ListItem
                      button
                      component={Link}
                      to={"/contact_the_artist"}
                      onClick={toggleDrawer("bottom", false)}
                    >
                      <ListItemText primary="Contact the Artist" style={{color:SecondaryColor}}/>
                    </ListItem>
                    <Divider  style={{color:SecondaryColor}}/>
                  </List>
                </Drawer>  
              </Hidden>
              <Hidden mdDown>
              <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      color="inherit"
                      component={Link}
                      to={"/artist_bio"}
                      // className="linkstyle"
                    >
                      <Typography style={{textTransform:'capitalize'}}>Artist Bio</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="inherit"
                      component={Link}
                      to={"/art_showcase/all/all/latest"}
                      // className="linkstyle"
                    >
                      <Typography style={{textTransform:'capitalize'}}>Art Showcase</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="inherit"
                      component={Link}
                      to={"/contact_the_artist"}
                      className="linkstyle"
                    >
                      <Typography  style={{textTransform:'capitalize'}}>Contact the Artist</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
           
        </Toolbar>
        </Container>
      </AppBar>
    
  )
}
