import {useState, useEffect} from 'react'


 
import {Grid, Container,Typography} from '@mui/material'

import CustomTypography from '../Reusable/CustomTypography'; 

import {PrimaryColor,SecondaryColor} from '../Reusable/CustomThemes'
import { styled } from '@mui/system';
import axios from 'axios';
import {Loading} from '../components/Loading'
import { Helmet } from 'react-helmet-async';

import {SubscribeLetter} from './components/SubsribeLetter'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const CustomHeading = styled(Typography)(({   }) => ({
    position: 'relative',
    color:SecondaryColor,padding:"15px",
    display: 'inline-block',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: '50%',
      borderColor: SecondaryColor,
      borderStyle: 'solid',
    },
    '&:before': {
      top: 0,
      left: 0,
      borderTopWidth: '5px',
      borderLeftWidth: '5px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
    },
    '&:after': {
      bottom: 0,
      right: 0,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: '5px',
      borderRightWidth: '5px',
    },
  }));


export const HomeScreen=()=>{

  const [isLoading, setIsLoading] = useState(true);
  const [metaTitle,setMetaTitle] = useState("");
  const [metaKeywords,setMetaKeywords] = useState("");
  const [metaDesc,setMetaDesc] = useState("");


  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const getData=()=>{
    axios.get("/user/get_home_data"   ).then((res) => {
      if (res.data.status) {
        setMetaTitle(res.data.metadata.meta_title)
        setMetaDesc(res.data.metadata.meta_desc)
        setMetaKeywords(res.data.metadata.meta_keywords)
        setIsLoading(false)
        handleClick();
      } 
    });
  }


  useEffect(()=>{
    getData();
  },[])


    return (<div>
        {isLoading ? (
      <Loading />
    ) : (<Container maxWidth="lg">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metaKeywords} />

      </Helmet>
            <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            >
              
                <Grid item xs={12}>
                <Carousel 
                
                animation="slide"
                timeout={1200}
                autoPlay={false}
                showIndicators={true}
                showArrows={true}
                centerMode={true}
                centerSlidePercentage={100}
                infiniteLoop
                
                stopOnHover
                swipeable
                showThumbs={false}
                dynamicHeight={true}
                transitionTime={1200}
                statusFormatter={(current, total) => {
       
                  return ``;
                }}
                >
                <div>
                    <img src="./static/about/painting3.jpg" />
                    
                    <p className="legend1" >
                      <strong>Classical Canvases</strong>
                      <hr style={{ borderColor: '#301257', borderWidth: '1px', borderStyle: 'solid',margin:'10px' }}/>
                    Dive into a curated collection of classical art that bridges the past and present.
Explore masterpieces that have transcended time in our gallery.</p>
                </div>
                <div>
                    <img src="./static/about/painting3.jpg" />
                    <p className="legend1"  >
                      <strong>Modern Masterpieces</strong>
                      <hr style={{ borderColor: '#301257', borderWidth: '1px', borderStyle: 'solid',margin:'10px' }}/>
                      Explore the dynamic and expressive works of modern art.
Bold colors and innovative techniques define this vibrant collection.</p>
                </div>
                <div>
                    <img src="./static/about/painting3.jpg" />
                    <p className="legend1"  >
                      <strong>Impressionist Inspirations</strong>
                      <hr style={{ borderColor: '#301257', borderWidth: '1px', borderStyle: 'solid',margin:'10px' }}/>

                      Immerse yourself in the light and color of Impressionist paintings that capture fleeting moments in time.
See the world through an artist’s eyes.</p>
                </div>
            </Carousel>
                </Grid>
                <Grid item xs={12}>
                <br/>
                </Grid>
                <Grid item xs={12}>
                  
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="stretch"
                      
                    >
                      <Grid xs={12} style={{marginBottom:'20px'}}>
                      <Typography
                              variant="body1"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                textTransform:'capitalize',
                                color: SecondaryColor,
                              }}
                            >
                              <strong>Welcome to the Canvas of Dreams</strong><br/>
                            </Typography>
                            <hr/>
                      </Grid>
                      <Grid xs={12} style={{marginBottom:'20px'}}>
                      <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              Explore the captivating world of Varshi, an artist whose brushstrokes bring to life the vivid colors and deep emotions that reside within the human spirit. Each painting is a window into Varshi’s unique perspective, blending classical techniques with modern sensibilities to create a visual symphony that speaks directly to the soul.
                            </Typography>
                      </Grid>
                      <Grid xs={12} style={{marginBottom:'20px'}}>
                      <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >
                              From the subtle nuances of light in her impressionist inspirations to the bold contrasts in her modern masterpieces, Varshi's art is an ever-evolving journey of discovery. Step into a realm where each canvas tells a story, each hue carries a melody, and every art piece is an intimate dance of imagination and reality.


                            </Typography>
                      </Grid>
                      <Grid xs={12} style={{marginBottom:'20px'}}>
                      <Typography
                              variant="body2"
                              gutterBottom
                              style={{
                                textAlign: 'left',
                                color: SecondaryColor,
                              }}
                            >Join us in celebrating the power of art to transform, to inspire, and to connect. Discover the diverse collections that have made Varshi a beloved figure in the world of contemporary painting.

                            </Typography>
                      </Grid>
                    </Grid>
                  
                </Grid>
                <Grid item xs={12}>
                <br/>
                </Grid>
                <Grid item xs={12}>
                <SubscribeLetter />
                </Grid>
                <Grid item xs={12}>
                <br/>
                </Grid>
            </Grid>
         </Container>)}       
    </div>)
}

