import React from 'react';
import { Grid, Typography, Box,IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';

import { PrimaryColor, SecondaryColor } from '../../Reusable/CustomThemes'


const ArtDescription = ({ title, category, subcategory, description, createdDate, ownerName }) => {
    const url=window.location.href;
  return (
    <Box sx={{ padding: 2, boxShadow:'none', borderRadius: '8px', }}>
      <Grid container
  direction="column"
  justifyContent="flex-start"
  alignItems="stretch" spacing={2} style={{color:SecondaryColor}}>
        <Grid item>
          <Typography variant="h4" component="div" gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid item>

        </Grid>
        <Grid item></Grid>
        <Grid item style={{textAlign:"start"}}>
          <Typography variant="body2" component="div"  gutterBottom>
            <strong>Category:</strong> {category}
          </Typography>
        </Grid>
        <Grid item style={{textAlign:"start"}}>
          <Typography variant="body2" component="div" gutterBottom>
            <strong>Subcategory:</strong> {subcategory}
          </Typography>
        </Grid>
        <Grid item style={{textAlign:"start"}}>
          <Typography variant="body2" component="div" gutterBottom>
            <strong>Owner:</strong> {ownerName}
          </Typography>
        </Grid>
        <Grid item style={{textAlign:"start"}}>
          <Typography variant="body2" component="div" gutterBottom>
            <strong>Created Date:</strong> {createdDate}
          </Typography>
        </Grid>
         
        <Grid item style={{textAlign:"start"}}>
        <Typography variant="body2" gutterBottom>
            <strong>Share Options:</strong>
            <IconButton component="a" href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <FacebookIcon size={30} />
            </IconButton>
            <IconButton component="a" href={`https://www.linkedin.com/shareArticle?url=${url}`} target="_blank" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <LinkedInIcon size={30} />
            </IconButton>
            <IconButton component="a" href={`https://twitter.com/share?url=${url}`} target="_blank" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <TwitterIcon size={30} />
            </IconButton>
            <IconButton component="a" href={`https://wa.me/?text=${url}`} target="_blank" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <WhatsAppIcon size={30} />
            </IconButton>
            <IconButton component="a" href={`https://t.me/share/url?url=${url}`} target="_blank" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <TelegramIcon size={30} />
            </IconButton>
            <IconButton component="a" href={`mailto:?body=${url}`} target="_blank" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <EmailIcon size={30} />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item style={{textAlign:"start"}}>
          <Typography variant="body2" component="div" gutterBottom>
            <strong>Art Description:</strong> 
            <p>{description}</p>
          </Typography>
        </Grid>
        
      </Grid>
    </Box>
  );
};

export default ArtDescription;
