import React from 'react';
import { Card, CardMedia, CardContent, Typography, ButtonBase } from '@mui/material';
import { PrimaryColor, SecondaryColor } from '../../Reusable/CustomThemes'
 import { useNavigate } from 'react-router-dom';

const ArtCard = ({ image, title, category, subcategory, postedDate,id }) => {

  const navigate = useNavigate();

  
  const handleCardClick = () => {
    navigate(`/art_details/${id}`);
  };

  return (
    
    <Card  onClick={handleCardClick} style={{ cursor: 'pointer' }} >
      <CardContent>
        <img src={image} style={{height:"200px"}} />
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" style={{color:SecondaryColor, marginBottom:"5px"}}>
            {category}
          </Typography>
          <Typography variant="body2"  style={{color:SecondaryColor, marginBottom:"5px"}}>
            {subcategory}
          </Typography>
         
        <Typography variant="body2"  style={{color:SecondaryColor}}>
          Created Date: {postedDate}
        </Typography>
      </CardContent>
    </Card>
    
  );
}

export default ArtCard;
