import Compressor from 'compressorjs';
import React, { useContext, useEffect, useState } from 'react';
import CustomContainer from '../../Reusable/CustomContainer';
import { Box, Button, Card, CardMedia, Grid, MenuItem } from '@mui/material';
import CustomTypography from '../../Reusable/CustomTypography';
import { PrimaryColor, SecondaryColor } from '../../Reusable/CustomThemes';
import CustomTextField from '../../Reusable/CustomTextfield';
import { boxStyle } from '../../Reusable/CustomStyles';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { SnackbarContext } from '../../components/UserContext';
import { Loading } from "../../components/Loading";

export const EditSubCategory = () => {
  const [image, setImage] = useState([])
  const [err, setErr] = useState(0);
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [wait, setWait] = useState(true);
  const [oldImg, setOldImg] = useState("")


 
  const [ cats, setCats ] = useState([])

  const [categoryData, setCategoryData] = useState({
    cat_name: '',
    cat_desc: '',
    cat_status: '1',
    cat_image: null,
    cat_ids:''
  });

  const getData=async()=>{
    await axios.post("/admin/all_categories", ).then(response => {
      if (response.data.status) {
         
        setCats(response.data.data);
        // setWait(false)
      } else {
        console.error(response.data.msg);
      }
      
    });

    const formData = new FormData();
    formData.append('subcat_id', id);
    await axios.post("/admin/get_single_sub_category", formData).then(response => {
      if (response.data.status) {
        const subcategory = response.data.data;
        setCategoryData({
          cat_name: subcategory.subcat_name,
          cat_desc: subcategory.subcat_desc,
          cat_status: subcategory.subcat_status.toString(),
          cat_image: subcategory.subcat_image,
          cat_ids:subcategory.cat_ids
        });
        setOldImg(subcategory.subcat_image)
        // setCats(response.data.data);
        setWait(false)
      } else {
        console.error(response.data.msg);
      }
      
    });
  }


  useEffect(()=>{

    getData();
  },[])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData({ ...categoryData, [name]: value });
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      new Compressor(file, {
        quality: 0.8,
        success: (compressedResult) => {
          setImage(compressedResult);
          setCategoryData({ ...categoryData, cat_image: compressedResult });
        },
      });
    }
  };

  let compImage = (image) => {
    return new Promise((resolve, reject) => {
      new Compressor(image, {
        quality: 0.8,
        success: async (compressedResult) => {
          return resolve(compressedResult);
        }
      });
    })

  };
  const imageChange = async (e) => {

    if (e.target.files.length != 0) {
      var type = e.target.files[0].type;
      if (type === "image/jpeg" || type === "image/jpg" || type === "image/png") {
        const imagee = e.target.files[0];
        var img = await compImage(imagee);
        setImage({ raw: img, preview: URL.createObjectURL(img) });
      } else {
        alert("Please select only JPEG, JPG, PNG Images..")
      }
    }
    e.target.value = ""

  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErr(0);

    if (categoryData.cat_name === "") {
      setErr(1);
      setSnack({
        message: "Please Enter Category Title...",
        type: "error",
        open: true,
      });
      return;
    }

    if (categoryData.cat_ids === "") {
      setErr(4);
      setSnack({
        message: "Please Select a Category...",
        type: "error",
        open: true,
      });
      return;
    }

    if (categoryData.cat_desc === "") {
      setErr(2);
      setSnack({
        message: "Please Enter Category Description...",
        type: "error",
        open: true,
      });
      return;
    }

    if (image === null) {
      setErr(3);
      setSnack({
        message: "Please Choose Image",
        type: 'error',
        open: true,
        direction: "center"
      });
      return;
    }

    setWait(true);

    const formData = new FormData();
    formData.append('subcat_id', id);

    formData.append('subcat_name', categoryData.cat_name);
    formData.append('subcat_desc', categoryData.cat_desc);
    formData.append('subcat_status', categoryData.cat_status);
    formData.append('cat_ids', categoryData.cat_ids);
    if (image.length !== 0) {
      formData.append("subcat_image", image.raw)
    }

    try {
      const response = await axios.post("/admin/edit_sub_category", formData);
      if (response.data.status === true) {
        setSnack({
          message: response.data.msg,
          type: "success",
          open: true,
        });
        setWait(false);
        navigate("/admin_dashboard/all_sub_categories");
      } else {
        setSnack({
          message: response.data.msg,
          type: "error",
          open: true,
        });
        setWait(false);
      }
    } catch (error) {
      console.error('Error updating category:', error);
      setSnack({
        message: "Error updating category. Please try again.",
        type: "error",
        open: true,
      });
      setWait(false);
    }
  };

  return (
    <div>
      {wait ? (
        <Loading />
      ) : (
        <CustomContainer maxWidth="md"  m={1} p={3}>
        
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} textAlign="center">
                <CustomTypography variant="h5" style={{color:SecondaryColor }} gutterBottom>
              <span style={{color:SecondaryColor,textTransform:'uppercase'}}>Edit subcategory</span>
            </CustomTypography>
            <hr style={{color:SecondaryColor}}/>
            <br/>
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    name="cat_name"
                    value={categoryData.cat_name}
                    label="SubCategory Title *"
                    fullWidth
                    onChange={handleChange}
                    error={err === 1}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    name="cat_ids"
                    value={categoryData.cat_ids}
                    label="Select Category *"
                    error={err === 4}
                    select
                    fullWidth
                    onChange={handleChange}
                  >
                    <MenuItem value="">Please Select a Category</MenuItem>
                    {cats.map((cat) => (
                    <MenuItem key={cat.cat_id} value={cat.cat_id}>
                      {cat.cat_name} ({cat.cat_status===1?"Active":"In-Active"}) 
                    </MenuItem>
                  ))}
                  </CustomTextField>
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    name="cat_status"
                    value={categoryData.cat_status}
                    label="Status *"
                    select
                    fullWidth
                    onChange={handleChange}
                  >
                    <MenuItem value="1">Active</MenuItem>
                    <MenuItem value="0">In-Active</MenuItem>
                  </CustomTextField>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    multiline
                    rows={4}
                    name="cat_desc"
                    value={categoryData.cat_desc}
                    label="SubCategory Description *"
                    fullWidth
                    onChange={handleChange}
                    error={err === 2}
                  />
                </Grid>


                
                <Grid item xs={6}>
                  
                  <Button
                    variant="outlined"
                    size="small"
                    component="label"
                    fullWidth
                    onChange={imageChange}
                    error={err === 3 && true}
                    sx={{
                      color: SecondaryColor, border: `1px solid ${SecondaryColor}`, width: "100%", "&:hover": {
                        border: `1px solid ${SecondaryColor}`
                      }
                    }}
                  >
                    upload image
                    <input hidden type="file" />
                  </Button>
                </Grid>

                
                {/* {oldImg && (
                  <Grid item xs={6}>
                    <Card>
                      <CardMedia
                        component="img"
                        image={`http://127.0.0.1:5000/static/subcategory/${oldImg}`}
                        title="Category Image"
                        style={{ height: 300, width: 300 }}
                      />
                    </Card>
                  </Grid>
                )} */}


                {image.length !== 0
                  ?
                  <Grid item xs={6}  >
                    {image.preview &&
                      (<img src={image.preview} width="200" height="200" />)}
                  </Grid>
                  :
                  <Grid item xs={6}>
                    <img src={` /static/subcategory/${oldImg}`} width="200" height="200" />
                  </Grid>}


                <Grid item xs={12} textAlign="center">
                <br/><br/>
                  <Button
                    variant="contained"
                    disabled={wait}
                    type="submit"
                    style={{
                      backgroundColor: wait ? PrimaryColor : SecondaryColor,
                      width: "60%",
                      color: wait ? SecondaryColor : PrimaryColor,
                    }}
                  >
                    {wait ? "Please Wait...." : (<>UPDATE SUBCATEGORY</>)}
                  </Button>
                </Grid>
              </Grid>
            </form>
           
        </CustomContainer>
      )}
    </div>
  );
};
