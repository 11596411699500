import React,{useState} from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Carousel.css'
import 'yet-another-react-lightbox/styles.css';
import Lightbox from 'yet-another-react-lightbox';

const ArtCarousel = ({ images }) => {

  const [open, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };


  return (
    <>
    <Carousel
    animation="slide"
      timeout={1200}
      autoPlay={false}
      showIndicators={false}
      showArrows={true}
      centerMode={true}
      centerSlidePercentage={100}
      infiniteLoop
      
      stopOnHover
      swipeable
      showThumbs={true}
      dynamicHeight={true}
      transitionTime={1200}
      statusFormatter={(current, total) => {
       
        return ``;
      }}>
      {images.map((item, i) => (
        <div  
        onClick={() => handleImageClick(i)}
        style={{
            
                                          padding:"5px",
                                          cursor: "pointer",
                                          borderRadius:"5px",
                                   
        }}
        >
        
              <img src={"/static/arts/"+item} style={{borderRadius:"5px" ,maxHeight:"500px", height: "100%", objectFit: "contain"  }}   alt={item} />
    </div>
      ))}
    </Carousel>
    <Lightbox
        open={open}
        index={currentImageIndex}
        close={() => setOpen(false)}
        slides={images.map((item) => ({ src: `/static/arts/${item}` }))}
      />
    </>
  );
};

export default ArtCarousel;
