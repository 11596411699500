import { Button, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomContainer from "../Reusable/CustomContainer";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CustomBox from "../Reusable/CustomBox";
import EditIcon from "@mui/icons-material/Edit";
import CustomTypography from "../Reusable/CustomTypography";
import { DatePicker, Space } from "antd";
import { Loading } from "../components/Loading";
import { PrimaryColor, SecondaryColor } from "../Reusable/CustomThemes";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LockIcon from "@mui/icons-material/Lock";
import '../App.css'
var moment = require("moment-timezone");

export const SubscribersHistory = () => {
    let {date}=useParams()
    let dataa=useParams()
    const [userData,setUserData]=useState([])
    const [loading,setLoading]=useState(true)
    const [dateRange, setDateRange] = useState([]);
    const [datePick, setDatePick] = useState(date);
    const [page, setPage] = useState(10);
    const dateFormat = "YYYY-MM-DD";
    let history=useNavigate()

    useEffect(()=>{
        
        getData()
    
    },[dataa])

    const getData=async()=>{

        const formdata = new FormData();
        if (dataa.date === "all") {
          formdata.append("from_date", "all");
          formdata.append("to_date", "all");
        } else {
          formdata.append(
            "from_date",
            moment(dataa.date.split(",")[0]).format(dateFormat)
          );
          formdata.append(
            "to_date",
            moment(dataa.date.split(",")[1]).format(dateFormat)
          );
        }
        await axios.post("/admin/subscribers_history", formdata).then((res) => {
          if ( res.data.status === true) {
            setUserData(res.data.data);
            setLoading(false);
          }
        });
  
    }

      // Column definitions for the data grid
  const columns = [
    { field: "sub_id", headerName: "ID", width: 100 },
    { field: "name", headerName: "NAME", width: 300 },
    { field: "email", headerName: "EMAIL", width: 300 },
    { field: "sub_date1", headerName: "SUBSCRIBED DATE", width: 300 },
  ];
 
  // Function to handle date range change
  const handleDateChange = (dates) => {
  
    if (dates) {
      setDateRange(dates);
      setDatePick(dates);
      history("/admin_dashboard/subscribers_history/" + [dates[0].format(dateFormat), dates[1].format(dateFormat)]);
    } else {
      setDateRange([]);
      history("/admin_dashboard/subscribers_history/" + ["all"]);
    }
  };
  return (
    <div>
  
   {loading?<Loading />:(
        <CustomContainer maxWidth="lg">
          <CustomTypography variant="h5" style={{color:SecondaryColor }} gutterBottom>
              <span style={{color:SecondaryColor,textTransform:'uppercase'}}>Subscribers History</span>
            </CustomTypography>
            <hr style={{color:SecondaryColor}}/>
            <br/>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
             
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            // m={2}
            spacing={2}
          >
            <Grid item xs={12} md={6} lg={4}>
              <CustomTypography alignItems="left">
              <span style={{color:SecondaryColor,textTransform:'uppercase'}}>Subscribed Date</span>
                 <Space direction="horizontal" size={12}>
                  <DatePicker.RangePicker
                    format={dateFormat}
                    value={dateRange}
                    onChange={handleDateChange}
                    style={{
                      width: "100%",
                      borderColor:"black",
                    }}
                  />
                </Space>
              </CustomTypography>
            </Grid>
          </Grid>
          <CustomBox
            sx={{
                
              height: "500px",
              width: "100%",
              mt: 2,
            }}
          >
            <DataGrid
              hideFooterSelectedRowCount
              rows={userData}
              columns={columns}
              getRowId={(row) => row.sub_id}
              rowsPerPageOptions={[10, 20, 30]}
              pageSize={page}
              getRowHeight={() => "auto"}
              getRowClassName={() => 'custom-row'}
              sx={{
                ".MuiDataGrid-columnHeaders": {
                  backgroundColor: SecondaryColor,
                  color:PrimaryColor,
                },
                ".MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                ".css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root ": {
                  color:PrimaryColor,
                },
                ".MuiDataGrid-menuIcon": {
                  visibility: "visible",
                  display: "contents",
                },
                /* CSS to center buttons in DataGrid cells */

              }}
            />
          </CustomBox>
        </CustomContainer>
      )
}
    </div>
  );
};
