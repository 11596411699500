import React from 'react';
import {Typography}from "@mui/material"

const CustomTypography = ({...props}) => {
  return (
    <Typography 
    className='roboto'
    {...props}
    style={{letterSpacing:'1px'}}
    >
      {props.children}
    </Typography>
  );
};

export default CustomTypography;