import {
  Box,
  Button,
  Container,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  IconButton,
  InputAdornment,
  InputLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import CustomTextField from "../Reusable/CustomTextfield";
import { AdminContext, SnackbarContext } from "../components/UserContext";
import axios from "axios";
import CustomDialog from "../Reusable/CustomDilog";
import CustomTypography from "../Reusable/CustomTypography";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { PrimaryColor, SecondaryColor } from "../Reusable/CustomThemes";
import LoginIcon from "@mui/icons-material/Login";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
export const Login = () => {
  // State variables for managing user input, errors, loading state, and OTP
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [err, setErr] = useState(0);
  const [wait, setWait] = useState(false);
  const [showOtpDailog, setShowOtpDailog] = useState(false);
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // Context variables for handling snackbars and admin authentication
  const { snack, setSnack } = useContext(SnackbarContext);
  const { admin, setAdmin } = useContext(AdminContext);
  let history = useNavigate();
  // Function to toggle password visibility for current password
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // Function to handle form submission for login
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErr(0);
    if (!name.includes("@") && name.length !== 10) {
      setErr(1);
      setSnack({
        message: "Enter Valid Email-id or Mobile Number",
        type: "error",
        open: true,
        direction: "center",
      });
    } else if (pass.length <= 4) {
      setErr(2);
      setSnack({
        message: "Please enter Password",
        type: "error",
        open: true,
        direction: "center",
      });
    } else {
      setWait(true);
      const formdata = new FormData();
      formdata.append("uname", name);
      formdata.append("pass", pass);
      await axios.post("/admin/ad_login", formdata).then((res) => {
        if (res.data.status === true) {
          setWait(false);
          setShowOtpDailog(true);
        } else {
          setWait(false);
          setSnack({
            message: res.data.msg,
            type: "error",
            open: true,
            direction: "center",
          });
        }
      });
    }
  };
  // Function to validate OTP
  const validateotp = async (e) => {
    e.preventDefault();
    setErr(0);
    if (otp === "") {
      setErr(3);
      setSnack({
        message: "Please enter OTP",
        type: "error",
        open: true,
        direction: "center",
      });
    } else {
      setWait(true);
      const formData = new FormData();
      formData.append("uname", name);
      formData.append("pass", pass);
      formData.append("otp", otp);
      await axios.post("/admin/validate_otp", formData).then((res) => {
        if (res.data.status === true) {
          setAdmin(res.data.data);
          setWait(false);
          setSnack({
            message: res.data.msg,
            type: "success",
            open: true,
            direction: "left",
          });
          setShowOtpDailog(false);
          history("/admin_dashboard");
        } else {
          setWait(false);
          setSnack({
            message: res.data.msg,
            type: "error",
            open: true,
            direction: "center",
          });
        }
      });
    }
  };
  return (
    <div>
      <Container maxWidth="sm">
        <Toolbar />
        <Toolbar />
       
          <form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={12}>
              <CustomTypography variant="h5" style={{color:SecondaryColor }} gutterBottom>
              <span style={{color:SecondaryColor,textTransform:'uppercase'}}>Admin Login</span>
            </CustomTypography>
            <hr style={{color:SecondaryColor}}/>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Email-ID / Mobile Number *"
                  name="name"
                  error={err == 1 && true}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  size="small"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth size="small">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password *
                  </InputLabel>
                  <Input
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password *"
                    name="pass"
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    error={err === 2 && true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} className="text-center">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={wait}
                  style={{
                    backgroundColor: wait ? PrimaryColor : SecondaryColor,
                    width: "60%",
                    color: wait ? SecondaryColor : PrimaryColor,
                  }}
                >
                  {wait ? (
                    "Please Wait...."
                  ) : (
                    <>
                      LOGIN
                    </>
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        
        <CustomDialog open={showOtpDailog}>
          <DialogTitle id="alert-dialog-title">
            <Typography
              variant="h5"
              className="text-center"
              style={{ textAlign: "center" }}
            >
              Enter OTP
            </Typography>
            <hr style={{}} />
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <form onSubmit={validateotp} style={{ textAlign: "center" }}>
              <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={12}>
                  <br />
                  <CustomTextField
                    label="OTP *"
                    error={err == 3 && true}
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    size="small"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} className="text-center">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={wait}
                    style={{
                      backgroundColor: wait ? PrimaryColor : SecondaryColor,
                      width: "60%",
                      color: wait ? SecondaryColor : PrimaryColor,
                    }}
                  >
                    {wait ? "Please Wait...." : (<>Validate OTP </>)}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </CustomDialog>
      </Container>
    </div>
  );
};
