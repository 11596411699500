import {Grid} from '@mui/material';
import {CircularProgress} from '@mui/material';
import { SecondaryColor } from '../Reusable/CustomThemes';

export const Loading = () =>{

    return (<Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    style={{height:'70vh' }}
    >
            <CircularProgress style={{color:SecondaryColor}} />
            </Grid>);

}