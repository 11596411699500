import {useState, useEffect} from 'react'

import {Grid, Container, Typography} from '@mui/material'

import CustomTypography from '../Reusable/CustomTypography'; 

import {PrimaryColor,SecondaryColor} from '../Reusable/CustomThemes'
import { styled } from '@mui/system';

import axios from 'axios';
import {Loading} from '../components/Loading'
import { Helmet } from 'react-helmet-async';


const CustomHeading = styled(Typography)(({   }) => ({
    position: 'relative',
    color:SecondaryColor,padding:"15px",
    display: 'inline-block',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: '50%',
      borderColor: SecondaryColor,
      borderStyle: 'solid',
    },
    '&:before': {
      top: 0,
      left: 0,
      borderTopWidth: '5px',
      borderLeftWidth: '5px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
    },
    '&:after': {
      bottom: 0,
      right: 0,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: '5px',
      borderRightWidth: '5px',
    },
  }));

export const Terms=()=>{

    const [isLoading, setIsLoading] = useState(true);
  const [metaTitle,setMetaTitle] = useState("");
  const [metaKeywords,setMetaKeywords] = useState("");
  const [metaDesc,setMetaDesc] = useState("");


  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const getData=()=>{
    axios.get("/user/get_terms_data"   ).then((res) => {
      if (res.data.status) {
        setMetaTitle(res.data.metadata.meta_title)
        setMetaDesc(res.data.metadata.meta_desc)
        setMetaKeywords(res.data.metadata.meta_keywords)
        setIsLoading(false)
        handleClick();
      } 
    });
  }


  useEffect(()=>{
    getData();
  },[])

    return (<div>
         {isLoading ? (
      <Loading />
    ) : (<Container>
        <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metaKeywords} />

      </Helmet>
            <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            >
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                <CustomHeading variant="h4">
      Terms Of Use
    </CustomHeading>
                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                    <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Introduction</b>
                    </CustomTypography>
                    <CustomTypography>
                    Welcome to varshisartgallery.com ("we," "our," or "us"). By accessing or using our website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these terms, please do not use our website.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Use of Website</b>
                    </CustomTypography>
                    <CustomTypography>
                    You agree to use our website only for lawful purposes. You must not use our website in any way that breaches any applicable local, national, or international law or regulation.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Intellectual Property</b>
                    </CustomTypography>
                    <CustomTypography>
                    All content on our website, including images, text, graphics, and logos, is the property of varshisartgallery.com or its content creators and is protected by copyright and other intellectual property laws. You may not reproduce, distribute, or create derivative works from any content on our site without our express written permission.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>User-Submitted Content</b>
                    </CustomTypography>
                    <CustomTypography>
                    By submitting a contact form, you grant us the right to use the information provided for the purposes stated in our Privacy Policy. You agree that all information provided by you is accurate, current, and complete.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Limitation of Liability</b>
                    </CustomTypography>
                    <CustomTypography>
                    varshisartgallery.com will not be liable for any direct, indirect, incidental, special, or consequential damages that result from the use or inability to use our website. We do not warrant that our website will be uninterrupted or error-free.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Governing Law</b>
                    </CustomTypography>
                    <CustomTypography>
                    These Terms and Conditions are governed by and construed in accordance with the laws of India, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Changes to These Terms</b>
                    </CustomTypography>
                    <CustomTypography>
                    We may modify these Terms and Conditions at any time. We will notify you of any changes by posting the new Terms and Conditions on our website. Your continued use of the site after any changes have been made will constitute your acceptance of the new terms.
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12} style={{textAlign:'left' }}>
                <CustomTypography sx={{marginBottom:'10px',color:SecondaryColor}}>
                    <b>Contact Us</b>
                    </CustomTypography>
                    <CustomTypography>
                    If you have any questions about these Terms and Conditions, please contact us at:
                    <ul style={{color:SecondaryColor}}>
                        <li><b>Email:</b> zealousartistvarshi@gmail.com</li>
                    </ul>
                    </CustomTypography>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
            </Grid>
        </Container>)}
    </div>)
}

