import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import {
  Snackbar,
  Alert,
  Slide,
  useScrollTrigger,
  Fab,
  Zoom,
  Toolbar,
} from "@mui/material";
import { Login } from './Admin/login';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { AdminContext, SnackbarContext } from './components/UserContext';
import { AdminDashboard } from './Admin/dasboard';
import { Navbar } from './components/Navbar';
import { Loading } from './components/Loading';
import { Footer } from './components/Footer';
import {HomeScreen} from './User/HomeScreen';
import {Contact} from './User/ContactUs';
import {About} from './User/About';
import {MyArts} from './User/Art'
import {Terms} from './User/Terms'
import {Privacy} from './User/Privacy';
import {ArtDetails} from './User/ArtDetails';


import { HelmetProvider } from 'react-helmet-async';


function App() {
  const [isloading, setIsloading] = useState(true);
  const [admin, setAdmin] = useState();
  const [snack, setSnack] = useState({
    message: "",
    color: "",
    open: false,
    direction: "",
  });

  useEffect(() => {
    checkSession()
  }, [])

  
  const checkSession = async () => {
    setIsloading(true);
    await axios.post("/admin/check_session").then((res) => {
      if (res.data.status === true) {
        setAdmin(res.data.data);
        setIsloading(false);
      } else {
        setAdmin(null);
        setIsloading(false);
      }
    });
  }

  const getAnchorOrigin = () => {
    if (snack.type === "error") {
      return { vertical: "bottom", horizontal: "center" };
    } else {
      return { vertical: "bottom", horizontal: "left" };
    }
  };
  return (
    <div className="page-container">
      <Snackbar
        open={snack.open}
        autoHideDuration={2000}
        onClose={() => {
          setSnack((prevdata) => {
            return {
              ...prevdata,
              open: false,
            };
          });
        }}
        TransitionComponent={Slide}
        anchorOrigin={getAnchorOrigin()}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setSnack((prevdata) => {
              return {
                ...prevdata,
                open: false,
              };
            });
          }}
          severity={snack.type}
        >
          {snack.message}
        </Alert>
      </Snackbar>
      {isloading ? (<Loading />) : (
          <HelmetProvider>
         <Router>
        <AdminContext.Provider value={{ admin, setAdmin }}>
          {/* <div className="content-wrap"> */}

          <SnackbarContext.Provider value={{ snack, setSnack }}>
            <div class="content-wrap">
             
                <Navbar />
                <Routes>
                  <Route path="/" element={ <HomeScreen />} />
                  <Route path="/artist_bio" element={ <About />} />
                  <Route path="/terms_of_use" element={ <Terms />} />
                  <Route path="/privacy_notice" element={ <Privacy />} />
                  <Route path="/contact_the_artist" element={ <Contact />} /> 
                  <Route path="/art_showcase/:cat/:subcat/:sort" element={ <MyArts />} />
                  <Route path="/art_details/:id" element={ <ArtDetails />} />
                  <Route path="/login_ad" element={admin ? <Navigate to="/admin_dashboard" /> : <Login />} />
                  <Route path="/admin_dashboard/*" element={admin ? <AdminDashboard /> : <Navigate to="/" />} />
                </Routes>
              

            </div>
          </SnackbarContext.Provider>
          <Toolbar />
          <Footer />
          {/* </div> */}
        </AdminContext.Provider>
        </Router>
        
</HelmetProvider>
      )}

    </div>
  );
}

export default App;
