import { FormControl, Grid, IconButton, Input, InputAdornment, InputLabel } from '@mui/material'
import React, { useContext, useState } from 'react'
import CustomBox from '../Reusable/CustomBox'
import CustomTypography from '../Reusable/CustomTypography'
import CustomButton from '../Reusable/CustomButton'
import CustomContainer from '../Reusable/CustomContainer'

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AdminContext, SnackbarContext } from '../components/UserContext'
import axios from 'axios'
import { PrimaryColor, SecondaryColor } from '../Reusable/CustomThemes'
import { boxStyle } from '../Reusable/CustomStyles'
export const UpdatePassword=()=> {
  // State variables for managing form data, errors, loading state, and password visibility
    const { admin } = useContext(AdminContext)
  const [data, setData] = useState({
    currentPassword: "",
    newPassword: "",
    conformPassword: "",
  });
   // Function to handle changes in form fields
  const changeHandlear = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const { snack, setSnack } = useContext(SnackbarContext)
  const [err, setErr] = useState(0)
  const [wait, setWait] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  // Function to toggle password visibility for current password
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // Function to toggle password visibility for new password
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  // Function to toggle password visibility for confirm password
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault(); 
  };
  // Function to handle form submission
  const submitHandlear = async(e) => {
    e.preventDefault();
    setErr(0);
    if (data.currentPassword.length <= 5) {
      setErr(3);
      setSnack({
        message: "Please Enter Current Password...",
        type: "error",
        open: true,
        direction: "center",
      });
    } else if (data.newPassword.length <= 5) {
      setErr(4);
      setSnack({
        message: "Please Enter New Password...",
        type: "error",
        open: true,
        direction: "center",
      });
    }
    else if (data.conformPassword.length <= 5) {
      setErr(5);
      setSnack({
        message: "Please Enter Confirm Password...",
        type: "error",
        open: true,
        direction: "center",
      });
    }
     else if (data.conformPassword !== data.newPassword) {
      setErr(6);
      setSnack({
        message: "PassWord And Conform Password Are Not Same...",
        type: "error",
        open: true,
        direction: "center",
      });
    } else {
      setWait(true);
      const formdata = new FormData();
      formdata.append("admin_id", admin.id)
      formdata.append("old_admin_pass", data.currentPassword);
      formdata.append("new_pass", data.newPassword);
      await axios.post("/admin/updateadmin_pass", formdata).then((res) => {
        if (res.data.status === true) {
          setSnack({
            message: res.data.msg,
            type: "success",
            open: true,
          });
          setWait(false);
        }
        else {
          setSnack({
            message: res.data.msg,
            type: "error",
            open: true,
          });
          setWait(false);
        }
      });
    }
  };
  return (
    <div>
      <CustomContainer maxWidth="md"  m={1} p={3}>
                 
                    <form onSubmit={submitHandlear}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={4}
                        >
                            <Grid item xs={12} >
                               
                            <CustomTypography variant="h5" style={{color:SecondaryColor }} gutterBottom>
              <span style={{color:SecondaryColor,textTransform:'uppercase'}}>Update password</span>
            </CustomTypography>
            <hr style={{color:SecondaryColor}}/>
            <br/>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl variant="standard"  fullWidth size="small">
                                    <InputLabel htmlFor="standard-adornment-password"  >
                                        Current Password *
                                    </InputLabel>
                                    <Input
                                         id="standard-adornment-password"
                                         
                                        type={showPassword ? "text" : "password"}
                                        endAdornment={
                                            <InputAdornment position="end"  >
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Current Password *"
                                        name="currentPassword"
                                        value={data.currentPassword}
                                        onChange={changeHandlear}
                                        error={err === 3 && true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl variant="standard"  fullWidth size="small">
                                    <InputLabel htmlFor="outlined-adornment-password">
                                        New Password *
                                    </InputLabel>
                                    <Input
                                         id="standard-adornment-password"
                                         
                                        type={showPassword1 ? "text" : "password"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword1}
                                                    onMouseDown={handleMouseDownPassword1}
                                                    edge="end"
                                                >
                                                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="New Password *"
                                        name="newPassword"
                                        value={data.newPassword}
                                        onChange={changeHandlear}
                                        error={(err === 4 && true) || (err === 6 && true)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl variant="standard"  fullWidth size="small">
                                    <InputLabel htmlFor="outlined-adornment-password">
                                        Confirm Password *
                                    </InputLabel>
                                    <Input
                                         id="standard-adornment-password"
                                         
                                        type={showPassword2 ? "text" : "password"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword2}
                                                    onMouseDown={handleMouseDownPassword2}
                                                    edge="end"
                                                >
                                                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Conform Password *"
                                        name="conformPassword"
                                        value={data.conformPassword}
                                        onChange={changeHandlear}
                                        error={(err === 5 && true) || (err === 6 && true)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} m={5} textAlign="center" >
                            <CustomButton variant='contained' type='submit' disabled={wait} label={wait ? "Please Wait...." : "Update Password"} sx={{ width: "60%", backgroundColor: wait ? PrimaryColor : SecondaryColor, color: wait ? SecondaryColor : PrimaryColor, "&:hover": { backgroundColor: wait ? "wait" : SecondaryColor } }} />
                        </Grid>
                    </form>
              
            </CustomContainer>
    </div>
  )
}
