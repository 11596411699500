import Compressor from 'compressorjs';
import React, { useContext, useEffect, useState } from 'react';
import CustomContainer from '../../Reusable/CustomContainer';
import { Box, Button, Card, CardMedia, Grid, MenuItem } from '@mui/material';
import CustomTypography from '../../Reusable/CustomTypography';
import { PrimaryColor, SecondaryColor } from '../../Reusable/CustomThemes';
import CustomTextField from '../../Reusable/CustomTextfield';
import { boxStyle } from '../../Reusable/CustomStyles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SnackbarContext } from '../../components/UserContext';
import { Loading } from "../../components/Loading";

export const AddSubCategory = () => {
  const [image, setImage] = useState(null);
  const [err, setErr] = useState(0);
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate(); // Correct hook usage
  const [wait, setWait] = useState(true); // Added wait state

  const [ cats, setCats ] = useState([])

  const [categoryData, setCategoryData] = useState({
    cat_name: '',
    cat_desc: '',
    cat_status: '1',
    cat_image: null,
    cat_ids:''
  });



  const getData=async()=>{
    await axios.post("/admin/all_categories", ).then(response => {
      if (response.data.status) {
         
        setCats(response.data.data);
        setWait(false)
      } else {
        console.error(response.data.msg);
      }
      
    });
  }


  useEffect(()=>{

    getData();
  },[])



  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData({ ...categoryData, [name]: value });
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      new Compressor(file, {
        quality: 0.8,
        success: (compressedResult) => {
          setImage(compressedResult);
          setCategoryData({ ...categoryData, cat_image: compressedResult });
        },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErr(0);

    if (categoryData.cat_name === "") {
      setErr(1);
      setSnack({
        message: "Please Enter Category Title...",
        type: "error",
        open: true,
      });
      return;
    }

    if (categoryData.cat_ids === "") {
      setErr(4);
      setSnack({
        message: "Please Select a Category...",
        type: "error",
        open: true,
      });
      return;
    }

    if (categoryData.cat_desc === "") {
      setErr(2);
      setSnack({
        message: "Please Enter Category Description...",
        type: "error",
        open: true,
      });
      return;
    }

    if (image === null) {
      setErr(3);
      setSnack({
        message: "Please Choose Image",
        type: 'error',
        open: true,
        direction: "center"
      });
      return;
    }

    setWait(true);

    const formData = new FormData();
    formData.append('subcat_name', categoryData.cat_name);
    formData.append('subcat_desc', categoryData.cat_desc);
    formData.append('cat_ids', categoryData.cat_ids);
    formData.append('subcat_status', categoryData.cat_status);
    formData.append('subcat_image', image);

    try {
      const response = await axios.post("/admin/add_sub_category", formData);
      if (response.data.status === true) {
        setSnack({
          message: response.data.msg,
          type: "success",
          open: true,
        });
        setWait(false);
        navigate("/admin_dashboard/all_sub_categories");
      } else {
        setSnack({
          message: response.data.msg,
          type: "error",
          open: true,
        });
        setWait(false);
      }
    } catch (error) {
      console.error('Error adding category:', error);
      setSnack({
        message: "Error adding category. Please try again.",
        type: "error",
        open: true,
      });
      setWait(false);
    }
  };

  return (

    <div>
    {wait ? (
      <Loading />
    ) : (
    <CustomContainer maxWidth="md"  m={1} p={3} >
    
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
            <CustomTypography variant="h5" style={{color:SecondaryColor }} gutterBottom>
              <span style={{color:SecondaryColor,textTransform:'uppercase'}}>Add subcategory</span>
            </CustomTypography>
            <hr style={{color:SecondaryColor}}/>
            <br/>
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name="cat_name"
                value={categoryData.cat_name}
                label="Sub-Category Title *"
                fullWidth
                onChange={handleChange}
                error={err === 1}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name="cat_status"
                value={categoryData.cat_status}
                label="Status *"
                select
                fullWidth
                onChange={handleChange}
              >
                <MenuItem value="1">Active</MenuItem>
                <MenuItem value="0">In-Active</MenuItem>
              </CustomTextField>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="cat_ids"
                value={categoryData.cat_ids}
                label="Select Category *"
                error={err === 4}
                select
                fullWidth
                onChange={handleChange}
              >
                <MenuItem value="">Please Select a Category</MenuItem>
                {cats.map((cat) => (
                <MenuItem key={cat.cat_id} value={cat.cat_id}>
                  {cat.cat_name} ({cat.cat_status===1?"Active":"In-Active"}) 
                </MenuItem>
              ))}
              </CustomTextField>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                multiline
                rows={4}
                name="cat_desc"
                value={categoryData.cat_desc}
                label="Sub-Category Description *"
                fullWidth
                onChange={handleChange}
                error={err === 2}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                component="label"
                sx={{
                  color: SecondaryColor,
                  border: `1px solid ${SecondaryColor}`,
                  width: "50%",
                  '&:hover': {
                    border: `1px solid ${SecondaryColor}`,
                  },
                }}
              >
                Upload Image *
                <input
                  type="file"
                  hidden
                  accept="image/jpeg,image/jpg,image/png"
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
            {image && (
              <Grid item xs={6}>
                <Card>
                  <CardMedia
                    component="img"
                    image={URL.createObjectURL(image)}
                    title="Category Image"
                    style={{ height: 300, width: 300 }}
                  />
                </Card>
              </Grid>
            )}
            <Grid item xs={12} textAlign="center">
              <br/><br/>
              <Button
                variant="contained"
                disabled={wait}

                type="submit"
                style={{
                  backgroundColor: wait ? PrimaryColor : SecondaryColor,
                  width: "60%",
                  color: wait ? SecondaryColor : PrimaryColor,
                }}
              >

                {wait ? "Please Wait...." : (<> ADD SUBCATEGORY</>)}

              </Button>
            </Grid>
          </Grid>
        </form>
       
    </CustomContainer>
       )}
    </div>
  );
};
