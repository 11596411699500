import CustomTypography from "../Reusable/CustomTypography";
import { PrimaryColor,SecondaryColor } from "../Reusable/CustomThemes";
import {Grid,Container} from '@mui/material';

import {Link} from "react-router-dom";
export const Footer = () => {

  return (
    <div className="main-footer" style={{color:SecondaryColor}}>
      <br/>
       <Container>
      <Grid
         container
         direction="row"
         justifyContent="center"
         alignItems="center"
         spacing={2}
      >
        <Grid item xs={6} sm={6}>
             
            <Link to="/terms_of_use" style={{ color: SecondaryColor }}>Terms of Use</Link>
        </Grid>
        <Grid item xs={6} sm={6}>
         
        <Link to="/privacy_notice" style={{ color: SecondaryColor }}>Privacy Notice</Link>
            {/* <a
              href="/privacy_notice"
               
              style={{ color: SecondaryColor }}
            >
              Privacy Notice
            </a> */}
        </Grid>
        <Grid item xs={12}>
          <hr/>
        </Grid>
        <Grid item xs={12}>
        <CustomTypography sx={{ color: SecondaryColor }}>
            Designed and Developed by&nbsp;
            <a
              href="https://arawinz.com/"
              target="_blank"
              style={{ color: SecondaryColor }}
            >
              Arawinz soft solutions pvt ltd. © 2018 - 2024
            </a>
          </CustomTypography>
        </Grid>
      </Grid>
      </Container> 
      
      <br/>
    </div>
  );
};