import Compressor from 'compressorjs';
import React, { useContext, useEffect, useState } from 'react';
import CustomContainer from '../../Reusable/CustomContainer';
import { Box, Button, Card, CardMedia, Grid, MenuItem } from '@mui/material';
import CustomTypography from '../../Reusable/CustomTypography';
import { PrimaryColor, SecondaryColor } from '../../Reusable/CustomThemes';
import CustomTextField from '../../Reusable/CustomTextfield';
import { boxStyle } from '../../Reusable/CustomStyles';
import axios from 'axios';
import { useNavigate,useParams } from 'react-router-dom';
import { SnackbarContext } from '../../components/UserContext';
import { Loading } from "../../components/Loading";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import {TextField} from '@mui/material'
export const EditArt = () => {
   
    const { id } = useParams();
  const [err, setErr] = useState(0);
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate(); // Correct hook usage
  const [wait, setWait] = useState(true); // Added wait state
  const [images,setImages]=useState([]);

  const [oldImages,setOldImages]=useState([]);
  const [ cats, setCats ] = useState([])

  const [ subCats, setSubCats ] = useState([])

  const [artData, setArtData] = useState({
    art_name: '',
    art_desc: '',
    art_cat: '',
    art_subcat: '',
    art_status: '1',
    art_posted_date: '',
    art_owner:''
  });



  const getData=async()=>{
    await axios.post("/admin/all_categories", ).then(response => {
      if (response.data.status) {
         
        setCats(response.data.data);
        // setWait(false)
      } else {
         
      }
      
    });


    await axios.post("/admin/all_sub_categories", ).then(response => {
        if (response.data.status) {
           
          setSubCats(response.data.data);
          // setWait(false)

           
        } else {
           
        }
        
      });

      const formData = new FormData();
      formData.append('art_id', id);

      await axios.post("/admin/get_single_art", formData).then(response => {
        if (response.data.status) {
           
            //setSubCats(response.data.data);
          // setWait(false)
          setOldImages(response.data.images)
          const art = response.data.data;
           
          
          setArtData({
            art_name: art.art_title,
            art_desc: art.art_desc,
            art_cat: art.art_category,
            art_subcat: art.art_subcategory,
            art_status: art.art_status,
            art_posted_date: art.art_posted_date,
            art_owner:art.art_owner_name
          })
          setWait(false)
        } else {
           
        }
        
      });
  }


  useEffect(()=>{

    getData();
  },[])



  const handleChange = (e) => {
    const { name, value } = e.target;
    // setArtData({ ...artData, [name]: value });
    if (name === 'art_cat') {
      setArtData({ 
          ...artData, 
          [name]: value,  // Update 'art_cat'
          art_subcat: '' // Reset 'art_subcat'
      });
  } else {
      setArtData({
          ...artData,
          [name]: value
      });
  }
       
  };

  let compImage =   (image) => {
    return new Promise((resolve,reject)=>{
      new Compressor(image, {
        quality: 0.8,  
        success: async (compressedResult) => {
          return resolve(compressedResult);  
        }
      });
    })
    
  };


  const deleteImage=async(image)=>{
    setWait(true);
    const formData = new FormData();
    formData.append('art_id', id);
    formData.append('delete_image', image);
    const response = await axios.post("/admin/delete_art_image", formData);
      if (response.data.status === true) {
        getData();
        setSnack({
          message: response.data.msg,
          type: "success",
          open: true,
        });
      }else{
        setWait(false)
        setSnack({
          message: response.data.msg,
          type: "success",
          open: true,
        });
      }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErr(0);

    if (artData.art_name === "") {
      setErr(1);
      setSnack({
        message: "Please Enter Art Title...",
        type: "error",
        open: true,
      });
      return;
    }

    if (artData.art_cat === "") {
        setErr(2);
        setSnack({
          message: "Please Select a Category...",
          type: "error",
          open: true,
        });
        return;
      }

      if (artData.art_subcat === "") {
        setErr(3);
        setSnack({
          message: "Please Select a Subcategory...",
          type: "error",
          open: true,
        });
        return;
      }


      if (artData.art_owner=== "") {
        setErr(4);
        setSnack({
          message: "Please Enter Art Owner Name...",
          type: "error",
          open: true,
        });
        return;
      }


      if (artData.art_posted_date=== "") {
        setErr(5);
        setSnack({
          message: "Please Select Art Created Date...",
          type: "error",
          open: true,
        });
        return;
      }

    if (artData.art_desc === "") {
      setErr(6);
      setSnack({
        message: "Please Write something about the ART...",
        type: "error",
        open: true,
      });
      return;
    }

    if (images.length + oldImages.length ===0) {
      setErr(7);
      setSnack({
        message: "Please Select atleast one Image",
        type: 'error',
        open: true,
        direction: "center"
      });
      return;
    }

    setWait(true);

    const formData = new FormData();
    formData.append('art_id', id);
    formData.append('art_title', artData.art_name);
    formData.append('art_category', artData.art_cat);
    formData.append('art_subcategory', artData.art_subcat);
    formData.append('art_desc', artData.art_desc);
    formData.append('art_status', artData.art_status);
    formData.append('art_owner_name', artData.art_owner);
    formData.append('art_posted_date', artData.art_posted_date);
    // formData.append('subcat_image', image);
    for(var i=0;i<images.length;i++){
              
        formData.append('art_images',images[i].raw);
    }
    try {
      const response = await axios.post("/admin/edit_art", formData);
      if (response.data.status === true) {
        setSnack({
          message: response.data.msg,
          type: "success",
          open: true,
        });
        setWait(false);
        navigate("/admin_dashboard/all_arts");
      } else {
        setSnack({
          message: response.data.msg,
          type: "error",
          open: true,
        });
        setWait(false);
      }
    } catch (error) {
      console.error('Error adding art:', error);
      setSnack({
        message: "Error adding art. Please try again.",
        type: "error",
        open: true,
      });
      setWait(false);
    }
  };

  return (

    <div>
    {wait ? (
      <Loading />
    ) : (
    <CustomContainer maxWidth="md"  m={1} p={3}>
      
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
            <CustomTypography variant="h5" style={{color:SecondaryColor }} gutterBottom>
              <span style={{color:SecondaryColor,textTransform:'uppercase'}}>Edit Art</span>
            </CustomTypography>
            <hr style={{color:SecondaryColor}}/>
            <br/>
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name="art_name"
                value={artData.art_name}
                label="Art Title *"
                fullWidth
                onChange={handleChange}
                error={err === 1}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name="cat_status"
                value={artData.art_status}
                label="Status *"
                select
                fullWidth
                onChange={handleChange}
              >
                <MenuItem value="1">Active</MenuItem>
                <MenuItem value="0">In-Active</MenuItem>
              </CustomTextField>
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name="art_cat"
                value={artData.art_cat}
                label="Select Category *"
                error={err === 2}
                select
                fullWidth
                onChange={handleChange}
              >
                <MenuItem value="">Please Select a Category</MenuItem>
                {cats.map((cat) => (
                <MenuItem key={cat.cat_id} value={cat.cat_id}>
                  {cat.cat_name} ({cat.cat_status===1?"Active":"In-Active"}) 
                </MenuItem>
              ))}
              </CustomTextField>
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name="art_subcat"
                value={artData.art_subcat}
                label="Select Subcategory *"
                error={err === 3}
                select
                fullWidth
                onChange={handleChange}
              >
                <MenuItem value="">Please Select a Subcategory</MenuItem>
                {subCats.map((subcat) => {
                    if(artData.art_cat===subcat.cat_id){
               return (<MenuItem key={subcat.subcat_id} value={subcat.subcat_id}>
                  {subcat.subcat_name} ({subcat.subcat_status===1?"Active":"In-Active"}) 
                </MenuItem>);
                    }
              })}
              </CustomTextField>
            </Grid> 
            <Grid item xs={6}>
              <CustomTextField
                name="art_owner"
                value={artData.art_owner}
                label="Art Owner *"
                fullWidth
                onChange={handleChange}
                error={err === 4}
              />
            </Grid>
            <Grid item xs={6} style={{textAlign:"left"}}>
             <label>Created Date *</label>   
            <input name="art_posted_date"
                value={artData.art_posted_date}
                onChange={handleChange} type="date" style={{width:"100%"}} />
            {/* <DatePicker
                label="Posted Date *"
                name="art_posted_date"
                value={artData.art_posted_date}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
            /> */}
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                multiline
                rows={4}
                name="art_desc"
                value={artData.art_desc}
                label="Art Description *"
                fullWidth
                onChange={handleChange}
                error={err === 6}
              />
            </Grid>
            {(oldImages.length!==0  ) && (<Grid item xs={12}>
            
            <p>Already Added Images: </p>
            </Grid>)
              }
            {(oldImages.length!==0  ) ? (<Grid item xs={12}>
                <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={3}
                >
                {oldImages.map((image)=>{
                   
                   return <Grid item>
                       <Card  >
                       <CardMedia
                                style={{height: 200,width:200}}
                                image={"/static/arts/"+image} 
                                title={ image} 
                                />  
                               {oldImages.length!==0 && <Button variant="contained"  component="span" style={{ border:"1px solid "+PrimaryColor, backgroundColor:SecondaryColor,color:PrimaryColor}} fullWidth onClick={()=>{
                                    
                                     deleteImage(image);
                                     
                                }}>
                       DELETE IMAGE
                    </Button>} 
                       </Card>
                       </Grid>
               })}
               </Grid>
            </Grid>):""}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                component="label"
                sx={{
                  color: SecondaryColor,
                  border: `1px solid ${SecondaryColor}`,
                  width: "50%",
                  '&:hover': {
                    border: `1px solid ${SecondaryColor}`,
                  },
                }}
              >
                Upload Images *
                <input
                  type="file"
                  hidden
                  multiple
                  accept="image/jpeg,image/jpg,image/png"
                  onChange={ async(e)=>{
                    if(e.target.files.length!=0){
                       
                      if(images.length+e.target.files.length<=3){
                           
                           var arr=[];
                          for(var i=0;i<e.target.files.length;i++){
                              var type= e.target.files[i].type;
                              if(type==="image/jpeg" || type==="image/jpg" ||type==="image/png" ){
                                const image=e.target.files[i];
                                var img= await compImage(image);  
                                arr.push({raw:img,preview:URL.createObjectURL(img)});  
                              }else{
                                  alert("Please select only JPEG, JPG, PNG Images..")
                              }
                          }
                          setImages([...images,...arr]); 
                           
                      }else{
                          alert("Maximum Image limit is 3.");
                          
                      }
                  }
                  e.target.value=""
                }}
                />
              </Button>
            </Grid>
            
            {(images.length!==0  ) ? (<Grid item xs={12}>
                <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={3}
                >
    
               {images.map((image)=>{
                   
                   return <Grid item>
                       <Card  >
                       <CardMedia
                                style={{height: 200,width:200}}
                                image={image.preview}
                                title={image.preview} 
                                />  
                                <Button variant="contained"  component="span" style={{ border:"1px solid "+PrimaryColor, backgroundColor:SecondaryColor,color:PrimaryColor}} fullWidth onClick={()=>{
                                    
                                    setImages(images.filter(item => item.preview !== image.preview));
                                     
                                }}>
                       DELETE IMAGE
                    </Button>
                       </Card>
                       </Grid>
               })}
               </Grid>
            </Grid>):""}
            <Grid item xs={12} textAlign="center">
              <br/><br/>
              <Button
                variant="contained"
                disabled={wait}

                type="submit"
                style={{
                  backgroundColor: wait ? PrimaryColor : SecondaryColor,
                  width: "60%",
                  color: wait ? SecondaryColor : PrimaryColor,
                }}
              >

                {wait ? "Please Wait...." : (<> UPDATE ART</>)}

              </Button>
            </Grid>
          </Grid>
        </form>
       
    </CustomContainer>
       )}
    </div>
  );
};
