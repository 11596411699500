import { PrimaryColor, SecondaryColor } from "../../Reusable/CustomThemes";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LockIcon from "@mui/icons-material/Lock";
import CustomContainer from "../../Reusable/CustomContainer";
import CustomBox from "../../Reusable/CustomBox";
import CustomTypography from "../../Reusable/CustomTypography";
import { Loading } from "../../components/Loading";
import { useNavigate } from "react-router-dom";

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export const AllCategories = () => {
  // State variables initialization
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios.post('/admin/all_categories')
      .then(response => {
        if (response.data.status) {
           
          setCategories(response.data.data);
        } else {
          console.error(response.data.msg);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
        setLoading(false);
      });
  }, []);


  const columns = [
    { field: 'cat_id', headerName: 'ID', width: 200 },
    { field: 'cat_name', headerName: 'NAME', width: 200 },
    {
      field: "cat_image",
      headerName: "IMAGE",
      width: 200,
      renderCell: (row) =>
      (<div>
        <img src={"/static/category/"+row.row.cat_image} style={{height:"100px"}}  />
        {/* <span
          className="dot"
          style={{
            width: 10,
            height: 10,
            borderRadius: "50%",
            backgroundColor: row.row.art_status == 1 ? "green" : "red",
            marginRight: 5,
          }}
        ></span> */}
        {/* <span>{row.row.art_status == 1 ? "Active" : "In-Active"}</span> */}
      </div>)
    },
    // { field: 'cat_desc', headerName: 'Description', width: 200 },
    {
      field: "cat_status",
      headerName: "STATUS",
      width: 200,
      renderCell: (row) =>
      (<div>
        <span
          className="dot"
          style={{
            width: 10,
            height: 10,
            borderRadius: "50%",
            backgroundColor: row.row.cat_status == 1 ? "green" : "red",
            marginRight: 5,
          }}
        ></span>
        <span>{row.row.cat_status == 1 ? "Active" : "In-Active"}</span>
      </div>)
    },
    
    {
      field: 'actions',
      headerName: 'EDIT CATEGORY',
      width: 150,
      renderCell: (params) => (
        <Grid container spacing={1}>
          <Grid item>
            <IconButton
              onClick={() => navigate(`/admin_dashboard/edit_category/${params.row.cat_id}`)}
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      )
    },
    { field: 'cat_added_date', headerName: 'ADDED DATE', width: 200 },
    { field: 'cat_updated_date', headerName: 'UPDATED DATE', width: 200 },
  ];

  if (loading) {
    return <Loading />;
  } return (
    <CustomContainer>
      <CustomBox>
        <CustomTypography variant="h4" style={{color:SecondaryColor }} gutterBottom>
          <span style={{color:SecondaryColor,textTransform:'uppercase'}}>Categories</span>
        </CustomTypography>
        <hr style={{color:SecondaryColor}}/>
        <br/>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: SecondaryColor }}

            onClick={() => navigate('/admin_dashboard/add_category')}
          >
            <AddPhotoAlternateIcon fontSize="small"/>&nbsp;&nbsp;Add Category
          </Button>

        </Grid>
        <br/>
        {/* {JSON.stringify(categories,null,2)} */}
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            hideFooterSelectedRowCount
            getRowId={(row) => row.cat_id}
            rowsPerPageOptions={[10, 20, 30]}
            pageSize={10}
            getRowHeight={() => "auto"}
            getRowClassName={() => 'custom-row'}

            rows={categories}
            columns={columns}
            disableSelectionOnClick
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: SecondaryColor,
                color: PrimaryColor,
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              ".css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root ": {
                color: PrimaryColor,
              },
              ".MuiDataGrid-menuIcon": {
                visibility: "visible",
                display: "contents",
              },
            }}
          />
        </div>
      </CustomBox>
    </CustomContainer>
  );
};
