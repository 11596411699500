import React, { useContext } from 'react'
import { AdminContext } from '../components/UserContext'
import { Navigate, Route, Routes } from 'react-router-dom'
import { UpdateProfile } from './UpdateProfile'
import { UpdatePassword } from './UpdatePassword'
import { Home } from './home'
import { ContactHistory} from './ContactHistory'
import { SubscribersHistory } from './SubscribersHistory'

import { AllCategories } from "./Categories/AllCategories";
import { AddCategory } from "./Categories/AddCategory";
import { EditCategory } from "./Categories/EditCategory";

import { AllSubCategories} from "./SubCategories/AllSubCategories";
import { AddSubCategory} from "./SubCategories/AddSubCategory";
import { EditSubCategory} from "./SubCategories/EditSubCategory";


import {AllArts} from "./Arts/Arts";
import {AddArt} from "./Arts/AddArt";
import {EditArt} from "./Arts/EditArt";


import {AllMetaData} from './MetaData/AllMetaData'
import {EditMetaData} from './MetaData/EditMetaData'
export const AdminRoutes = () => {
  // Access the admin context to check if the user is an admin
  const { admin } = useContext(AdminContext)
  return (
    <div>
      
      <Routes>

        <Route path='/update_profile' element={admin ? <UpdateProfile /> : <Navigate to={"/"} />} />
        <Route path='/contact_history/:date' element={admin ? <ContactHistory /> : <Navigate to={"/"} />} />
        <Route path='/subscribers_history/:date' element={admin ? <SubscribersHistory /> : <Navigate to={"/"} />} />
         
        <Route path='/update_password' element={admin ? <UpdatePassword /> : <Navigate to={"/"} />} />

        <Route path='/' element={admin ? <Home /> : <Navigate to={"/"} />} />

        <Route path='/all_categories' element={admin ? <AllCategories /> : <Navigate to={"/"} />} />
        <Route path='/add_category' element={admin ? <AddCategory /> : <Navigate to={"/"} />} />
        <Route path='/edit_category/:id' element={admin ? <EditCategory /> : <Navigate to={"/"} />} />
      
      
        <Route path='/all_sub_categories' element={admin ? < AllSubCategories/> : <Navigate to={"/"} />} />
        <Route path='/add_sub_category' element={admin ? <AddSubCategory /> : <Navigate to={"/"} />} />
        <Route path='/edit_sub_category/:id' element={admin ? <EditSubCategory /> : <Navigate to={"/"} />} />
    

        <Route path='/all_arts' element={admin ? < AllArts/> : <Navigate to={"/"} />} />
        <Route path='/add_art' element={admin ? <AddArt /> : <Navigate to={"/"} />} />
        <Route path='/edit_art/:id' element={admin ? <EditArt /> : <Navigate to={"/"} />} />

        <Route path='/all_metadata' element={admin ? < AllMetaData/> : <Navigate to={"/"} />} />
        <Route path='/edit_metadata/:id' element={admin ? < EditMetaData/> : <Navigate to={"/"} />} />
        {/* <Route path='/all_arts' element={admin ? <AllArts /> : <Navigate to={"/"} />} />
        <Route path='/add_art' element={admin ? <AddArt /> : <Navigate to={"/"} />} />
        <Route path='/edit_art/:id' element={admin ? <EditArt /> : <Navigate to={"/"} />} /> */}
        
      </Routes>
       
    </div>
  )
}
